import FormRecord from "models/FormRecord";
import Form from "models/Form";
import { doRecursively } from "@arup-group/dhub-forms-engine";
import { getSignedUrl as getSignedUrls } from "utils/sync/getSignedUrl";

export async function cacheFloorplans(
	assetId: string | undefined,
	formRecords: FormRecord[] | undefined,
	authorization: string,
) {
	const recordsByAsset = await (assetId ? FormRecord.byAssetId(assetId) : []);
	const formIds = [
		...new Set([...(formRecords?.map((r) => r.form_id) ?? []), ...recordsByAsset.map((r) => r.form_id)]),
	];
	const assetIds = [...new Set([...(formRecords?.map((r) => r.asset_id) ?? []), ...(assetId ? [assetId] : [])])];
	const forms = await Form.getMany(formIds);
	const filepathsForAllForms = forms
		.map((form) => {
			const filepaths: string[] = Object.values(
				doRecursively({
					fields: form.fields,
					action: ({ field }) => {
						const isDrawing = field.type === "drawings";
						if (isDrawing) {
							return {
								[field.name]:
									field.choices
										?.filter((choice) => !choice.parent || assetIds.includes(choice.parent as string))
										.map((choice) => String(choice.filepath)) || [],
							};
						}
						return { [field.name]: [] };
					},
				}),
			).flat();
			return filepaths;
		})
		.flat();

	if (filepathsForAllForms && filepathsForAllForms.length) {
		console.log("Downloaded drawings for:", assetIds.length ? assetIds.length + "assets" : assetId);
	}
	const signedUrls = await getSignedUrls(
		filepathsForAllForms.map((it) => ({
			filepath: it,
			thumbnail: false,
			type: "drawing",
		})),
		authorization,
	);

	const cache = await caches.open("dhub-forms-images");
	const promises = Object.entries(signedUrls).map(async ([filepath, url]) => {
		if (!url) return;
		const cacheKey = `drawings/${filepath}`;
		const exists = await cache.match(cacheKey);
		if (!exists) {
			await fetch(url).then((r) => cache.put(cacheKey, r));
		}
	});
	await Promise.allSettled(promises);
	return signedUrls;
}
