import React, { useState, useEffect } from "react";

import { IonIcon, IonCard } from "@ionic/react";
import { brushOutline } from "ionicons/icons";
import { Controller } from "react-hook-form";

import { IImage } from "../../../../../interfaces/IImage";
import { FormField } from "../../../../../models/Form";
import SketchCanvasModal from "./SketchCanvasModal";
import { useSmartFieldCtx } from "../../hooks/useSmartForm";

interface IProps {
	field: FormField<IImage[]>;
}

const SketchCanvas: React.FC<IProps> = (props) => {
	const { field } = props;
	const [open, setOpen] = useState(false);
	const [newSketch, setNewSketch] = useState<IImage>();

	const { dig, control, currentValue, applyChange } = useSmartFieldCtx(field);

	const { name } = dig;

	useEffect(() => {
		if (!newSketch || !applyChange) return;
		const current = currentValue as IImage[] | undefined;
		applyChange(name, [...(current || []), newSketch], {
			shouldTouch: true,
			shouldDirty: true,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newSketch?.filepath]);

	return (
		<>
			<Controller
				name={name}
				control={control}
				rules={{}}
				render={() => {
					return (
						<IonCard
							className="cameraButton"
							type={"button"}
							color={"#EFEFEF"}
							onClick={() => {
								setOpen(true);
							}}
						>
							<IonIcon
								style={{
									fontSize: "2rem",
									color: "#9B9B9B",
									display: "block",
								}}
								icon={brushOutline}
							/>
						</IonCard>
					);
				}}
			/>
			<SketchCanvasModal open={open} setOpen={setOpen} setOnSave={setNewSketch} />
		</>
	);
};
export default SketchCanvas;
