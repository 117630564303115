import { IConfig } from "config";

const envName = window.location.hostname.split(".")[1];

if (!envName) throw new Error("Unknown dhub environment");

const config: IConfig = {
	DOMAIN: `forms.${envName}.dhub.arup.com`,
	API: `https://api.${envName}.dhub.arup.com`,
	DHUB: `https://app.${envName}.dhub.arup.com`,
	RELEASE: process.env.REACT_APP_GIT_SHA?.slice(0, 7) || "development",
	AZURE_AD_CLIENT_ID: "3bf00c3a-b11e-470f-8fd6-9406911022a3",
	AZURE_AD_TENANT_ID: "4ae48b41-0137-4599-8661-fc641fe77bea",
};

export default config;
