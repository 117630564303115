import React from "react";

import { IonModal, IonContent, IonIcon, IonButton, IonText } from "@ionic/react";
import { trash, close } from "ionicons/icons";
import { Pagination, Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "@ionic/react/css/ionic-swiper.css";

import { useTranslation } from "react-i18next";

import { IImage } from "interfaces/IImage";
import ImageFallback from "./ImageFallback";

interface IProps {
	items: IImage[];
	selected?: IImage;
	setSelected: React.Dispatch<React.SetStateAction<IImage | undefined>>;
	onDelete: (toDelete: string) => void;
}

const ImageModal: React.FC<IProps> = (props: IProps) => {
	const { selected, setSelected, onDelete, items } = props;
	const { t, i18n } = useTranslation();

	return (
		<IonModal
			isOpen={selected !== undefined}
			canDismiss={true}
			backdropDismiss={true}
			onDidDismiss={() => setSelected(undefined)}
			onWillDismiss={() => setSelected(undefined)}
		>
			<IonButton
				id="iconButton"
				fill="clear"
				onClick={() => setSelected(undefined)}
				style={{
					width: "4rem",
					marginLeft: "auto",
				}}
			>
				<IonIcon
					src={close}
					style={{
						fontSize: "2.5rem",
						color: "var(--ion-color-primary)",
					}}
				/>
			</IonButton>
			<IonContent forceOverscroll={false}>
				<div
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<Swiper
						style={{ width: "100%", flex: 1 }}
						modules={[Pagination, Zoom]}
						pagination={true}
						zoom={false}
						onSlideChange={(s) => {
							setSelected(items[s.activeIndex]);
						}}
						onInit={(s) => {
							s.slideTo(
								selected !== undefined && items.map((it) => it.filepath).includes(selected.filepath)
									? items.findIndex((it) => it.filepath === selected.filepath)
									: 0,
							);
						}}
					>
						{items?.map((photo: IImage) => (
							<SwiperSlide
								key={photo.filepath}
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<ImageFallback
									thumbnail={false}
									loading="lazy"
									style={{
										width: "100%",
										objectFit: "contain",
									}}
									photo={photo}
								/>
							</SwiperSlide>
						))}
					</Swiper>
					<IonButton
						style={{
							height: "3rem",
							margin: "auto",
							marginTop: "1rem",
							marginBottom: "1rem",
						}}
						shape="round"
						onClick={() => {
							console.log("debug Deleting onClick", selected);
							if (selected !== undefined) {
								onDelete(selected.filepath);
							}
						}}
					>
						<IonText>
							<IonIcon icon={trash} />
							&#x2800; {i18n.format(t("delete"), "capitalize")}
						</IonText>
					</IonButton>
				</div>
			</IonContent>
		</IonModal>
	);
};
export default ImageModal;
