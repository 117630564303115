import Cookies from "universal-cookie";

import { useAppSelector } from "store";
import { useEffect } from "react";

const CF_AUTH_REDIRECTS: Record<string, string> = {
	// "forms.dhub.arup.com":
	// "https://arup.cloudflareaccess.com/cdn-cgi/access/login/forms.dhub.arup.com?kid=<<TBC>>",
	// "forms.dev.dhub.arup.com":
	// 	"https://arup.cloudflareaccess.com/cdn-cgi/access/login/forms.dev.dhub.arup.com?kid=9003820430b200549e113d9c5d457848676c643d36dd8399f31b291bbf37d070",
};

const COOLDOWN_SECONDS = 0.25 * 3600;

const useRefreshCFToken = () => {
	const online = useAppSelector((state) => state.connection.isOnline);
	const cloudflareBaseUrl: string | undefined = CF_AUTH_REDIRECTS[window.location.hostname];

	useEffect(() => {
		if (online && cloudflareBaseUrl) {
			// Autenticate with Cloudflare Access
			const lastLoginTimestamp = Number(localStorage.getItem("CFRefreshAttemptTimestamp"));
			const currentTimestamp = new Date().getTime();
			const cookieManager = new Cookies();
			const authCookie = cookieManager.get<string | undefined>("CF_Authorization");
			const redirectUrl = encodeURIComponent(window.location.pathname);
			if (!authCookie && currentTimestamp - lastLoginTimestamp > COOLDOWN_SECONDS) {
				localStorage.setItem("CFRefreshAttemptTimestamp", currentTimestamp.toString());
				window.location.href = `${cloudflareBaseUrl}&redirect_url=${redirectUrl}`;
			}
		}
	}, [online]);
};

export default useRefreshCFToken;
