import React from "react";
import { useParams } from "react-router-dom";
import { handleCfRedirect } from "utils/handleCfRedirect";

export const EmbeddedCFGuard: React.FC = () => {
	const redirectUrlEncoded = useParams<{ redirectUrl: string }>().redirectUrl;
	// check if token exists on the cookies, the cookie name is CF_Authorization
	const cookieExists: boolean = document.cookie.indexOf("CF_Authorization") > -1;
	const redirectUrl = decodeURIComponent(redirectUrlEncoded);
	if (cookieExists) {
		// if token exists, redirect to dhub. The url comes from a query parameter encoded called "redirect"
		// the dirty=true query parameter is necessary to let dhub know that the user already went through the this app.
		console.log("token exists, redirecting to " + redirectUrl);
		localStorage.setItem("cf-redirect", redirectUrl + "?dirty=true");
	} else {
		// if token does not exist, redirect to the login page.
		// As for 16/10/2023, Cloudfare is not used so we return the dirty=true query parameter anyways
		console.log("token does not exists, redirecting to " + redirectUrl);
		// localStorage.setItem("cf-redirect", redirectUrl);
		localStorage.setItem("cf-redirect", redirectUrl + "?dirty=true");
	}

	handleCfRedirect();

	return <></>;
};
