import Form from "models/Form";
import { IImage } from "../../interfaces/IImage";
import FormRecord from "../../models/FormRecord";
import doForEachImageInRecord from "./doForEachImageInRecord";

export const getAllLocalAndRemotePhotosInRecord = async (record: FormRecord) => {
	const form = await Form.get(record.form_id, true);
	const photos: IImage[] = [];
	doForEachImageInRecord(form.fields, record.data, (image: IImage) => photos.push(image));
	return photos;
};
