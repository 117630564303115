import React, { useEffect } from "react";

import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { filterOutline } from "ionicons/icons";

import { FormField } from "models/Form";
import { searchFilterFields } from "utils/searchFilterFields";
import { useAppDispatch, useAppSelector } from "store";
import { actions as filterActions } from "store/slices/filters";
import NavigationBreadcrumbs from "components/common/Form/components/FormPage/NavigationBreadcrumbs";
import { useParams } from "react-router";

interface IProps {
	field: FormField;
	totalCount: number;
	filteredCount: number;
	setFilterModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
type PropsType = IProps;

const RepeatableHeader: React.FC<PropsType> = (props) => {
	const { field, totalCount, filteredCount, setFilterModalIsOpen } = props;

	const { projectRef, assetId, recordId } = useParams<{
		projectRef: string;
		assetId: string;
		recordId: string;
	}>();
	const { filterFields } = useAppSelector((state) => state.filters);
	const formName = useAppSelector((state) => state.form.active?.name);
	const historyPath = useAppSelector((state) => state.history.absolutePath);
	const dispatch = useAppDispatch();

	useEffect(() => {
		const initializeFilters = async () => {
			const fieldsForFiltering = searchFilterFields(field.children || [], [], historyPath || "");
			dispatch(
				filterActions.INIT_FILTERS({
					projectRef,
					assetId,
					recordId,
					repeatableName: field.name,
					fields: fieldsForFiltering,
				}),
			);
		};
		initializeFilters();
	}, [field.name]);

	return (
		<>
			<NavigationBreadcrumbs />
			<IonItem lines="full" style={{ "--inner-padding-start": "1.5rem", "--inner-padding-end": "1.5rem" }}>
				<IonLabel className="ion-text-wrap" style={{ fontWeight: "bold", display: "flex", gap: ".25rem" }}>
					{field ? field.label : formName}
					<IonLabel
						className="capitalize-first-letter"
						style={{ color: "var(--ion-color-medium-tint)", fontSize: "0.65rem" }}
					>
						{filteredCount !== totalCount ? `(${filteredCount} / ${totalCount})` : `(${totalCount})`}
					</IonLabel>
				</IonLabel>
				{filterFields.length > 0 && (
					<IonIcon
						src={filterOutline}
						style={{
							cursor: "pointer",
							color: "#718096",
							margin: 0,
							paddingRight: "0",
							paddingLeft: "1.875rem",
						}}
						size="small"
						slot="end"
						onClick={() => setFilterModalIsOpen(true)}
					/>
				)}
			</IonItem>
		</>
	);
};

export default RepeatableHeader;
