import axios, { AxiosProgressEvent } from "axios";

import LocalImage from "../models/LocalImage";
import { chunkGenerator } from "../utils/chunk";
import { getUploadSignedUrls } from "./signedUrl";

const base64ImageToBlob = (base64Image: string) => {
	// Decode Base64 string
	const decodedData = window.atob(base64Image);
	// Create UNIT8ARRAY of size same as row data length
	const uInt8Array = new Uint8Array(decodedData.length);
	// Insert all character code into uInt8Array
	for (let i = 0; i < decodedData.length; ++i) {
		uInt8Array[i] = decodedData.charCodeAt(i);
	}
	// Return BLOB image after conversion
	return new Blob([uInt8Array], { type: "image/jpeg" });
};

const localImageToBlob = (img: LocalImage) => {
	if (img.file) return img.file;
	if (img.base64) return base64ImageToBlob(img.base64);
};

export const uploadImages = async (
	images: LocalImage[],
	makeProgress?: (bytes: number, total?: number, id?: string) => void,
	signal?: AbortSignal,
) => {
	const signedUrls = await getUploadSignedUrls(
		images.map((img) => ({ filepath: img.filepath, contentType: "images/*" })),
	);
	const responses = [];
	for (const chunk of chunkGenerator(images, 100))
		responses.push(
			...(await Promise.all(
				chunk.map((img) => {
					const signedUrl = signedUrls[img.filepath];
					// Pre-checks
					if (!signedUrl) throw new Error(`Invalid signed url ${signedUrl} for image ${img.filepath}`);
					// base64 encoded images will be deprecated, but we still need to handle potential users
					// with base64 images locally for backwards compatibility
					const blob = localImageToBlob(img);
					if (blob?.size === 0) {
						console.error(`Empty image ${JSON.stringify(img)} ${img}`);
					}
					if (!blob) return;
					const headers: Record<string, string> = { "Content-Type": "image/jpeg" };
					// Save blob to corresponding signed url
					if (!img.file) headers["Content-Encoding"] = "base64";
					return axios.put(`${signedUrls[img.filepath]}`, blob, {
						headers,
						signal,
						onUploadProgress: (progressEvent: AxiosProgressEvent) =>
							makeProgress && makeProgress(progressEvent.loaded, progressEvent.total, img.filepath),
					});
				}),
			)),
		);
	return responses;
};
