import { apiClient } from "api";

export interface GetSignedUrlRequest {
	filepath: string;
	type: "image" | "file" | "dfl";
	thumbnail?: boolean;
}

interface SignedURLRequest {
	filepath: string;
	formpath?: string;
	contentType?: string;
	folder?: "drawings" | "images" | "files" | "dfls";
}

export const getSignedUrls = async (data: GetSignedUrlRequest[]) => {
	return await apiClient
		.post<{ [filepath: string]: string }>(`forms/getSignedUrls/CFUrls`, data)
		.then((res) => res.data);
};

export interface CookieResponse {
	cookies: {
		"CloudFront-Key-Pair-Id": string;
		"CloudFront-Signature": string;
		"CloudFront-Expires"?: number;
		"CloudFront-Policy"?: string;
	};
	paths: string[];
}
export const getSignedCookies = async (data: { assetId: string }): Promise<CookieResponse> => {
	return await apiClient.post<CookieResponse>("forms/getSignedCookies/CFCookies", data).then((res) => res.data);
};

export const getUploadSignedUrls = async (requested: SignedURLRequest[]) => {
	return await apiClient.post<{ [filepath: string]: string }>(`forms/putSignedUrls`, requested).then((res) => {
		if (Object.values(res.data).length !== requested.length) {
			throw new Error("Unexpected number of signedUrls retrieved");
		}
		return res.data;
	});
};
