import React from "react";

import { IonModal, IonText, IonContent, IonIcon, IonItem, IonList } from "@ionic/react";
import { useTranslation } from "react-i18next";

import { Button } from "components/common/Button";
import { alertTriangle } from "assets/icons";

interface IProps {
	openId?: string | number;
	onClose: () => void;
	handleDelete: (idOrIdx: string | number) => void;
}

const DeleteItemModal: React.FC<IProps> = (props) => {
	const { openId, onClose, handleDelete } = props;
	const { t, i18n } = useTranslation();

	return (
		<IonModal isOpen={openId !== undefined} onWillDismiss={onClose} canDismiss={true}>
			<IonContent forceOverscroll={false}>
				<IonList
					lines={"none"}
					style={{
						display: "flex",
						height: "calc(100% - 8rem)",
						flexDirection: "column",
						justifyContent: "space-between",
						padding: "4rem 0",
					}}
				>
					<div>
						<IonItem>
							<IonIcon
								src={alertTriangle}
								style={{
									width: "6.938rem",
									height: "6rem",
									color: "#e61e28",
									marginLeft: "auto",
									marginRight: "auto",
								}}
							/>
						</IonItem>
						<IonItem>
							<IonText
								style={{
									marginLeft: "auto",
									marginRight: "auto",
									textAlign: "center",
								}}
							>
								{i18n.format(t("delete_item_msg"), "capitalize")} <br />
								{i18n.format(t("operation_cant_undone"), "capitalize")}
							</IonText>
						</IonItem>
					</div>
					<div
						style={{
							marginTop: "1rem",
							display: "flex",
							flexDirection: "column",
							justifyItems: "center",
							marginInline: "1rem",
						}}
					>
						<Button
							variant="filled"
							onClickFunction={() => {
								onClose();
								openId !== undefined && handleDelete(openId);
							}}
						>
							{i18n.format(t("delete_confirm"), "capitalize")}
						</Button>

						<Button variant="empty" onClickFunction={onClose}>
							{i18n.format(t("cancel"), "capitalize")}
						</Button>
					</div>
				</IonList>
			</IonContent>
		</IonModal>
	);
};
export default DeleteItemModal;
