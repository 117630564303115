import { dbPromise } from "../services/idb";
import { DigestedFieldReferenceLibrary } from "@arup-group/dhub-forms-engine";

export interface IRawDFL {
	record_id: string;
	dfl: DigestedFieldReferenceLibrary;
	hash: string;
}

export default class DigestedFieldLibrary implements IRawDFL {
	record_id: string;
	dfl: DigestedFieldReferenceLibrary;
	hash: string;

	constructor(hashedDFL: IRawDFL) {
		this.record_id = hashedDFL.record_id;
		this.dfl = hashedDFL.dfl;
		this.hash = hashedDFL.hash;
	}

	static async getAllKeys() {
		return await (await dbPromise).getAllKeys("dfls");
	}

	static async get(record_id: string) {
		return await (await dbPromise).get("dfls", record_id).then((r) => {
			if (r === undefined) throw new Error(`Key ${record_id} not found`);
			return new DigestedFieldLibrary(r);
		});
	}

	static async getMany(ids: string[]) {
		const tx = (await dbPromise).transaction("dfls", "readwrite");
		const store = tx.objectStore("dfls");
		const data = await Promise.all(ids.map((id) => store.get(id)));
		await tx.done;
		return data.filter((it): it is DigestedFieldLibrary => !!it);
	}

	static async getAll() {
		const data = await (await dbPromise).getAll("dfls").then((r) => r.map((ri) => new DigestedFieldLibrary(ri)));
		return data;
	}

	static async set(data: DigestedFieldLibrary) {
		await (await dbPromise).put("dfls", data);
		return new DigestedFieldLibrary(data);
	}

	static async setMany(data: DigestedFieldLibrary[]) {
		const tx = (await dbPromise).transaction("dfls", "readwrite");
		const store = tx.objectStore("dfls");
		await Promise.all(data.map((it) => store.put(it)));
		await tx.done;
	}

	static async deleteMany(ids: string[]) {
		const data = await DigestedFieldLibrary.getMany(ids);
		await Promise.all(data.map((it) => this.delete(it.record_id)));
	}

	static async delete(id: string) {
		return (await dbPromise).delete("dfls", id);
	}

	static async deleteManyIfExist(ids: string[]) {
		await Promise.all(ids.map((recordId) => this.deleteIfExist(recordId)));
	}

	static async deleteIfExist(id: string) {
		try {
			return (await dbPromise).delete("dfls", id);
		} catch (e) {
			console.log(e);
		}
	}

	save() {
		return DigestedFieldLibrary.set(this);
	}
}
