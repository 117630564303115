import React, { useEffect, useState } from "react";

import {
	IonModal,
	IonIcon,
	IonButton,
	IonItem,
	IonList,
	IonSelect,
	IonSelectOption,
	IonNote,
	IonText,
	IonLabel,
	IonHeader,
	IonActionSheet,
	IonToolbar,
	IonTitle,
	IonButtons,
} from "@ionic/react";
import { trashOutline, trash, close, arrowBack } from "ionicons/icons";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "@ionic/react/css/ionic-swiper.css";

import { useTranslation } from "react-i18next";

import OpenSeaDragonViewer from "./OpenSeaDragonViewer";
import { FormField } from "models/Form";
import { Controller } from "react-hook-form";
import { alertCircleOutline, informationCircleOutline } from "ionicons/icons";
import { Divider } from "../Divider";
import { IDrawingRef } from "interfaces/IDrawingRef";
import { crossClose } from "assets/icons";
import { Button } from "components/common/Button";
import "./Drawings.styles.css";
import { parseDrawing } from "./utils/parseDrawing";
import { useSmartFieldCtx } from "../../hooks/useSmartForm";

interface IProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	field: FormField<IDrawingRef>;
}

const DrawingModal: React.FC<IProps> = (props: IProps) => {
	const { open, setOpen, field } = props;
	const { t, i18n } = useTranslation();
	const [toBeDeleted, setToBeDeleted] = useState<boolean>(false);

	const { dig, control } = useSmartFieldCtx(field);

	const { name, relevant, required, hardRequired, disabled, choices, alert } = dig;

	const validChoices = choices ?? [];

	if (field.sortChoices === "ascending") validChoices.sort((a, b) => a.label.localeCompare(b.label));
	else if (field.sortChoices === "descending") validChoices.sort((a, b) => b.label.localeCompare(a.label));

	return (
		<Controller
			name={name}
			control={control}
			// defaultValue={field.defaultValue}
			rules={{
				required: hardRequired && relevant && validChoices.length !== 0,
			}}
			render={({ field: fieldRenderProps }) => {
				const value = parseDrawing(fieldRenderProps.value);
				const [drawingReference, setDrawingReference] = useState<string | null>(value?.ref ?? null);

				setTimeout(() => {
					if (drawingReference && !validChoices.map((ch) => ch.value).includes(drawingReference)) {
						setDrawingReference(null);
					}
					if (validChoices.length === 1 && drawingReference !== validChoices[0].value) {
						setDrawingReference(validChoices[0].value);
					}
				}, 0);

				useEffect(() => {
					// Perform simple string comparison to check if there has been a meaningful change
					const newValue = JSON.stringify({ ...value, ref: drawingReference });
					if (fieldRenderProps.value === newValue) return;
					fieldRenderProps.onBlur();
					fieldRenderProps.onChange(newValue);
				}, [drawingReference]);

				return (
					<IonModal
						isOpen={open}
						canDismiss={true}
						backdropDismiss={true}
						onDidDismiss={() => setOpen(false)}
						onWillDismiss={() => setOpen(false)}
						className="modal-fullscreen"
						animated={false}
					>
						<IonHeader mode="ios">
							<IonToolbar style={{ alignItems: "center", "--border-width": 0 }}>
								<IonButtons slot="start">
									<IonButton
										fill="clear"
										color="secondary"
										style={{ marginTop: 0, marginBottom: 0, marginLeft: "0.5rem" }}
										onClick={() => {
											setOpen(false);
										}}
									>
										<IonIcon
											icon={crossClose}
											size="small"
											style={{
												color: "#718096",
												fontSize: "0.875rem",
											}}
										/>
									</IonButton>
								</IonButtons>
								<IonTitle color="gray700">{i18n.format(t("add_location"), "capitalize")}</IonTitle>
								<IonButtons slot="end">
									<IonButton
										fill="clear"
										color="secondary"
										style={{ marginTop: 0, marginBottom: 0 }}
										onClick={() => {
											setToBeDeleted(true);
										}}
									>
										<IonIcon
											icon={trashOutline}
											size="small"
											style={{
												color: "#718096",
												cursor: "pointer",
											}}
										/>
									</IonButton>
								</IonButtons>
							</IonToolbar>
						</IonHeader>
						<IonList>
							<IonItem lines="none" style={{ marginBottom: "1rem" }}>
								<IonLabel className="ion-text-wrap" position="stacked" mode="ios">
									{field.label}
									{(required || hardRequired) && <span style={{ color: "red" }}>&nbsp;*</span>}
								</IonLabel>
								<IonSelect
									// React Hook Form Managed
									aria-label={`${field.label}-drawing`}
									ref={fieldRenderProps.ref}
									value={drawingReference}
									onIonChange={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setDrawingReference(e.detail.value);
									}}
									disabled={disabled || validChoices.length === 0}
									// Other config
									placeholder={field.description}
									multiple={false}
								>
									{validChoices.map((choice, idx) => (
										<IonSelectOption key={`${fieldRenderProps.name}-option-${idx}`} value={choice.value}>
											{choice.label}
										</IonSelectOption>
									))}
								</IonSelect>
								<Divider color="var(--ion-color-medium)" />
								{alert && (
									<IonNote
										color={field.alertColor}
										style={{
											display: "flex",
											gap: ".25rem",
											alignItems: "center",
											textAlign: "center",
											margin: ".25rem 0",
										}}
									>
										<IonIcon
											icon={
												field.alertIcon === "informationCircleOutline" ? informationCircleOutline : alertCircleOutline
											}
											color={field.alertColor}
											size="small"
										/>
										<IonText>{alert}</IonText>
									</IonNote>
								)}
							</IonItem>
						</IonList>
						<div
							style={{
								height: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							<OpenSeaDragonViewer
								currValue={value}
								onChange={fieldRenderProps.onChange}
								onBlur={fieldRenderProps.onBlur}
								image={validChoices.find((validChoice) => validChoice.value === drawingReference)?.filepath as string}
								digestedField={dig}
							/>
						</div>
						<div style={{ display: "flex", flexDirection: "column", marginBottom: "2rem", marginInline: "1rem" }}>
							<Button onClickFunction={() => setOpen(false)} variant="filled">
								<IonIcon
									style={{
										color: "white",
										marginRight: ".5rem",
									}}
									icon={arrowBack}
									size="small"
								/>
								{i18n.format(t("close"), "capitalize")}
							</Button>
						</div>
						<IonActionSheet
							isOpen={toBeDeleted}
							header={i18n.format(t("delete_file_msg"), "capitalize")}
							buttons={[
								{
									text: i18n.format(t("delete"), "capitalize"),
									role: "destructive",
									icon: trash,
									handler: () => {
										{
											fieldRenderProps.onBlur();
											fieldRenderProps.onChange(null);
											setOpen(false);
										}
									},
								},
								{
									text: i18n.format(t("cancel"), "capitalize"),
									icon: close,
									role: "cancel",
								},
							]}
							onDidDismiss={() => setToBeDeleted(false)}
						/>
					</IonModal>
				);
			}}
		/>
	);
};
export default DrawingModal;
