import React, { useEffect, useState } from "react";

import { IonContent, IonItem, IonPage, IonSpinner } from "@ionic/react";
import { useParams, withRouter } from "react-router-dom";

import { getAssetsWithLatestStatus } from "api/assetStatus";
import { getForms } from "api/forms";
import { getProjects } from "api/projects";
import { getRecords } from "api/records";
import useToken from "components/common/Auth/hooks/useToken";
import CookieProvider from "components/common/CookieProvider";
import Asset from "models/Asset";
import Form from "models/Form";
import FormRecord from "models/FormRecord";
import Project from "models/Project";
import { useAppDispatch } from "store";
import syncFunction from "utils/sync";
import RecordsView from "views/RecordsView/RecordsView";

interface State {
	project?: Project;
	asset?: Asset;
	forms: Form[];
	records: FormRecord[];
	loading: boolean;
	embedded?: boolean;
}
const EmbeddedRecordsView: React.FC = () => {
	const dispatch = useAppDispatch();
	const token = useToken();
	const { projectRef, assetId } = useParams<{
		projectRef: string;
		assetId: string;
	}>();

	const [localState, setLocalState] = useState<State>();

	useEffect(() => {
		let isCancelled = false;
		const initialize = async () => {
			if (token) {
				await syncFunction(token, [], [], [], projectRef, assetId);
			} else {
				console.error("EmbeddedView - No token found");
				return;
			}
			// Proceed record view initialization
			const projectQuery = await getProjects([projectRef]);
			await Project.setMany(projectQuery);
			const project = await Project.get(projectRef);
			const assetsQuery = await getAssetsWithLatestStatus({ assetIds: [assetId] });
			await Asset.setMany(assetsQuery.items);
			const asset = await Asset.get(assetId);
			const recordsQuery = await getRecords([projectRef], [assetId]);
			await FormRecord.setMany(recordsQuery);
			const formsQuery = await getForms([projectRef]);
			await Form.setMany(formsQuery);

			if (isCancelled) return;
			setLocalState({ project, asset, forms: formsQuery, records: recordsQuery, loading: true, embedded: true });
		};
		initialize().catch((err) => console.error(err));
		return () => {
			isCancelled = true;
		};
	}, [token, projectRef, assetId, dispatch]);

	useEffect(() => {
		// send a message to the parent to notify this view is ready
		window.parent.postMessage({ id: "formsEmbeddedViewReady" }, "*");
	}, []);

	return (
		<IonPage>
			<IonContent forceOverscroll={false} style={{ backgroundColor: "green" }}>
				{localState ? (
					<RecordsView embedded={true} />
				) : (
					<>
						<IonItem lines="none">
							<IonSpinner name="lines-sharp-small" style={{ margin: "auto" }} />
						</IonItem>
					</>
				)}
			</IonContent>
			<CookieProvider assetId={assetId} />
		</IonPage>
	);
};

export default withRouter(EmbeddedRecordsView);
