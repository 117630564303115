import { FormField } from "models/Form";

export const getPageFields = (nestedFields: FormField[], flattenedFieldPages: FormField[]) => {
	nestedFields.forEach((item) => {
		if (item.type === "drillDownGroup" || item.type === "repeatableGroup" || item.type === "inlineGroup") {
			flattenedFieldPages.push(item);
		}
		if (item.children) {
			getPageFields(item.children, flattenedFieldPages);
		}
	});
	return flattenedFieldPages;
};
