import devConfig from "./dev";
import dynConfig from "./dynamic";
import hpConfig from "./hp";
import localConfig from "./local";
import prodConfig from "./prod";

export interface IConfig {
	DOMAIN: `${string}.dhub.arup.com` | "localhost";
	API: string;
	DHUB: string;
	RELEASE: string;
	AZURE_AD_CLIENT_ID: string;
	AZURE_AD_TENANT_ID: string;
}

const allConfigs = [localConfig, devConfig, prodConfig, hpConfig];
let config: IConfig | undefined = allConfigs.find((it) => it.DOMAIN === window.location.hostname);

if (window.location.hostname === "localhost" && process.env.REACT_APP_ENV === "development") {
	config = devConfig;
} else if (window.location.hostname === "localhost") {
	config = localConfig;
} else if (!config) {
	config = dynConfig;
}

export default config as IConfig;
