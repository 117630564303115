import React, { createContext } from "react";

import Form from "models/Form";
import FormRecord from "models/FormRecord";
import { WidgetFactory } from "views/ReportView/components/WidgetFactory";
import { FormEngine } from "@arup-group/dhub-forms-engine";

interface ReportContextInterface {
	changes: string[] | undefined;
	engine: FormEngine | undefined;
}
export const ReportContext = createContext<ReportContextInterface>({
	changes: undefined,
	engine: undefined,
});

interface ReportRendererInterface {
	projectRef: string;
	assetId: string;
	form: Form;
	records: FormRecord[];
	changes: string[] | undefined;
}

export const ReportRenderer: React.FC<ReportRendererInterface> = (props: ReportRendererInterface) => {
	const { projectRef, assetId, form, records, changes } = props;

	const record = records.find((it) => it.form_id === form.id);
	const engine = new FormEngine(projectRef, assetId, form, records);

	return (
		<ReportContext.Provider
			value={{
				engine,
				changes,
			}}
		>
			<div
				style={{
					width: "100%",
					marginLeft: "1.5rem",
					background: "var(--ion-background-color)",
				}}
			>
				{(form.fields ?? []).map((field) => (
					<WidgetFactory key={field.name} formField={field} dataToRender={record?.data ?? {}} ancestorHistory={[]} />
				))}
			</div>
		</ReportContext.Provider>
	);
};
