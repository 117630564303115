import { FormField } from "../models/Form";

export interface FormFieldsWithPath extends FormField {
	path: string;
}
// TODO: Use doRecursive to get rid of this
export function searchFilterFields(
	nestedObject: FormField[],
	groupsResult: FormFieldsWithPath[],
	currentPath: string,
) {
	for (const item of nestedObject) {
		const path = `${currentPath}.${item.name}`;
    if (item.type === "repeatableGroup")
      continue
		if (item.filter)
			groupsResult.push({
				...item,
				path,
			});
		if (item.children) {
			searchFilterFields(item.children, groupsResult, path);
		}
	}

	return groupsResult;
}
