import React, { memo, PropsWithChildren } from "react";

import { IonItem, IonLabel } from "@ionic/react";

import { IFile } from "../../../../interfaces/IFile";
import { FormField } from "../../../../models/Form";
import FilePrompt from "../components/FilePrompt/FilePrompt";
import Gallery from "../components/Gallery";
import { useSmartFieldCtx } from "../hooks/useSmartForm";
import { useAppSelector } from "store";

interface IProps {
	field: FormField<IFile[]>;
}

type PropsType = IProps;
const Files: React.FC<PropsType> = (props) => {
	const { field } = props;

	const isFrozen = useAppSelector((state) => state.form.isFrozen);
	const { dig } = useSmartFieldCtx(field);

	const { relevant, disabled, required, hardRequired } = dig;

	return (
		<div
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{relevant ? (
				<IonItem lines="none">
					<IonLabel
						className="ion-text-wrap capitalize-first-letter"
						position="stacked"
						mode="ios"
						style={{
							opacity: isFrozen || disabled ? 0.3 : 1,
						}}
					>
						{field.label}
						{(required || hardRequired) && <span style={{ color: "red" }}>&nbsp;*</span>}
					</IonLabel>
					<Gallery field={field} variant="file">
						{!isFrozen && !disabled && <FilePrompt field={field} />}
					</Gallery>
				</IonItem>
			) : null}
		</div>
	);
};
const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(Files, propsAreEqual);
export default Memoized;
