import { doRecursively } from "@arup-group/dhub-forms-engine";
import { IImage } from "interfaces/IImage";
import get from "lodash.get";
import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";

const doForEachImageInRecord = (formFields: FormField[], record: FormValues, action: (img: IImage) => void) => {
	const fieldLibrary = doRecursively({
		fields: formFields,
		action: ({ field, path }) => ({ [path.join(".")]: field }),
		shouldDive: ({ field }) => field.type !== "repeatableGroup",
	});

	for (const [fieldPath, field] of Object.entries(fieldLibrary)) {
		if (field.type === "images") {
			const images = get(record, fieldPath, []) as (IImage | null)[];
			if (!Array.isArray(images)) {
				console.error(`Image field data is not an array: ${fieldPath}`);
				continue;
			}
			images.filter((it): it is IImage => !!it).forEach(action);
		} else if (field.type === "repeatableGroup") {
			const childrenData = get(record, fieldPath, []) as (FormValues | null)[];
			if (!Array.isArray(childrenData)) {
				console.error(`Repeatable group data is not an array: ${fieldPath}`);
				continue;
			}
			childrenData
				.filter((it): it is FormValues => !!it)
				.forEach((childData) => doForEachImageInRecord(field.children || [], childData, action));
		}
	}
};

export default doForEachImageInRecord;
