import { useEffect } from "react";

import config from "config";

let count = 0;

function saveAzureTokens(event: MessageEvent) {
	if (event.origin === config.DHUB && event.data.id === "azureAuthTokens") {
		console.log("saveAzureTokens", event.data);
		for (const key in event.data.listTokens) {
			sessionStorage.setItem(key, event.data.listTokens[key]);
		}
		setTimeout(() => window.location.reload(), 500);
	}
	return event;
}

export const useAutoLogin = () => {
	useEffect(() => {
		let currentAccessToken = undefined;
		for (let i = 0; i < sessionStorage.length; i++) {
			const key = sessionStorage.key(i);
			if (key && key.includes("accesstoken")) {
				currentAccessToken = sessionStorage.getItem(key);
			}
		}
		const expirationDate = currentAccessToken ? JSON.parse(currentAccessToken).expiresOn : 0;
		const isExpired = expirationDate * 1000 < Date.now();
		if (isExpired && count < 3) {
			window.parent.postMessage({ id: "requestAzureAuthTokens" }, config.DHUB);
			count++;
			window.addEventListener("message", saveAzureTokens);
		}
		return () => window.removeEventListener("message", saveAzureTokens);
	}, []);
};
