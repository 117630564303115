import React, { useState } from "react";

import { IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, useIonPopover } from "@ionic/react";
import { arrowBackOutline, personCircleOutline, cloudOfflineOutline, trashOutline } from "ionicons/icons";
import { useHistory, useParams } from "react-router-dom";
import { CallbackFunction } from "use-double-tap";

import { arupLogo } from "assets/icons";
import { useAssetName } from "components/common/Form/utils/useAssetName";
import { ConfirmDeleteModal } from "components/modals/ConfirmDeleteModal";
import { useAppSelector } from "store";
import { SyncStatus } from "utils/sync/isSynced";
import { getSyncIconDetails } from "utils/sync/getSyncIconDetails";

import UserPopover from "./UserPopover";
import useToken from "../Auth/hooks/useToken";

interface IProps {
	title: string | React.ReactNode;
	syncState?: SyncStatus;
	backUrl?: string;
	onBack?: React.MouseEventHandler<HTMLIonButtonElement>;
	onArupLogoDoubleTap?: CallbackFunction<Element>;
	syncDetails?: {
		variant: SyncStatus;
		openSyncModal: () => void;
	};
	canBeDeleted?: boolean;
}
type PropsType = IProps;

const Header: React.FC<PropsType> = (props) => {
	const { backUrl, onBack, onArupLogoDoubleTap, syncDetails, canBeDeleted = false } = props;
	const { projectRef, assetId } = useParams<{ projectRef: string; assetId: string }>();
	const history = useHistory();
	const [present, dismiss] = useIonPopover(UserPopover, {
		navigate: (url: string) => history.push(url),
		onHide: () => dismiss(),
	});
	const isOnline = useAppSelector((state) => state.connection.isOnline);
	const token = useToken()
	const { assetName } = useAssetName(projectRef, assetId);
	const [isDeleteConfirmModal, setDeleteConfirmModal] = useState(false);

	// TODO: This should come from config
	const projectLogoSrc = undefined;

	return (
		<React.Fragment>
			<IonHeader mode="ios">
				<IonToolbar style={{ alignItems: "center", "--border-width": 0 }}>
					<IonButtons slot="start">
						<IonButton
							fill="clear"
							color="secondary"
							routerLink={backUrl}
							routerDirection="back"
							onClick={backUrl ? onBack : onArupLogoDoubleTap}
							style={{ "--padding-start": "1rem" }}
						>
							{backUrl === undefined ? (
								<IonIcon src={projectLogoSrc || arupLogo} size="small" className="logo" />
							) : (
								<IonIcon
									src={arrowBackOutline}
									size="small"
									style={{
										color: "var(--ion-color-medium-tint)",
									}}
								/>
							)}
						</IonButton>
					</IonButtons>
					{backUrl && <IonTitle color="gray700">{assetName}</IonTitle>}
					<IonButtons slot="end">
						{canBeDeleted && (
							<IonButton
								fill="clear"
								color="primary"
								data-testid="userPanelButton"
								style={{ "--padding-end": "1rem" }}
								onClick={() => setDeleteConfirmModal(true)}
								disabled={!isOnline}
							>
								<IonIcon
									src={isOnline ? trashOutline : cloudOfflineOutline}
									style={{
										color: "var(--ion-color-medium-tint)",
									}}
								/>
							</IonButton>
						)}
						{syncDetails && isOnline && (
							<IonButton
								fill="clear"
								color="secondary"
								style={{ "--padding-end": "1rem" }}
								onClick={syncDetails.openSyncModal}
							>
								<IonIcon
									style={{
										color: "var(--ion-color-medium-tint)",
									}}
									src={getSyncIconDetails(syncDetails.variant, isOnline).icon}
								/>
							</IonButton>
						)}
						<IonButton
							fill="clear"
							color="secondary"
							data-testid="userPanelButton"
							style={{ "--padding-end": "1rem" }}
							onClick={(e) =>
								present({
									event: e.nativeEvent,
								})
							}
						>
							<IonIcon
								style={{
									color: isOnline && token ? "var(--ion-color-medium-tint)" : "var(--ion-color-danger-tint)",
								}}
								src={isOnline ? personCircleOutline : cloudOfflineOutline}
							/>
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			{canBeDeleted && (
				<ConfirmDeleteModal
					isOpen={isDeleteConfirmModal}
					projectRef={projectRef}
					assetId={assetId}
					onClose={() => {
						setDeleteConfirmModal(false);
					}}
				/>
			)}
		</React.Fragment>
	);
};

export default Header;
