import React from "react";

import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { IonLoading } from "@ionic/react";
import { loginRequest } from "config/msalConfig";
import { useAutoLogin } from "./hooks/useAutoLogin";

interface AuthProps {
	children?: React.ReactNode;
	interactWithPopup?: boolean;
}

export const Auth: React.FC<AuthProps> = (props) => {
	useAutoLogin();

	return (
		<MsalAuthenticationTemplate
			interactionType={props.interactWithPopup ? InteractionType.Popup : InteractionType.Redirect}
			authenticationRequest={loginRequest}
			loadingComponent={() => <IonLoading />}
		>
			{props.children}
		</MsalAuthenticationTemplate>
	);
};
