import React, { useEffect, useMemo, useState } from "react";

import { IonButton, IonCard, IonIcon, IonLabel, IonModal, IonProgressBar, useIonAlert } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { documentAttachOutline } from "ionicons/icons";

import { uploadFiles } from "api/files";
import { IFile } from "interfaces/IFile";
import LocalFile from "models/LocalFile";
import { FormField } from "models/Form";
import { useAppSelector } from "store";

import buildFilePath from "../../utils/buildFilePath";

import "./FilePrompt.styles.css";
import { useSmartFieldCtx } from "../../hooks/useSmartForm";

interface IProps {
	field: FormField<IFile[]>;
}

interface FileLoadingState {
	[requestId: string]: {
		progress: number;
		total: number;
	};
}

type PropsType = IProps;
const FilePrompt: React.FC<PropsType> = (props) => {
	const { field } = props;
	const { projectRef, assetId, recordId } = useAppSelector((state) => state.form);
	const historySlice = useAppSelector((state) => state.history);
	const { t, i18n } = useTranslation();

	const [loading, setLoading] = useState<FileLoadingState>();
	const [presentAlert] = useIonAlert();

	const abortController = useMemo(() => new AbortController(), []);
	useEffect(() => {
		return () => abortController.abort();
	}, []);

	const { dig, control } = useSmartFieldCtx(field);

	const { name } = dig;

	const uploadingMsg = i18n.format(t("uploading_msg"), "capitalize");
	const uploadingError = i18n.format(t("uploading_error"), "capitalize");

	const getOverallProgress = () => {
		if (!loading) return undefined;
		const values = Object.values(loading);
		const total = values.map((it) => it.total || 0).reduce((a, b) => a + b, 0);
		const current = values.map((it) => it.progress).reduce((a, b) => a + b, 0);
		return current / total;
	};

	return (
		<>
			<Controller
				name={name}
				control={control}
				render={({ field: fieldRenderProps }) => {
					return (
						<>
							<IonCard className="filePromptButton" type="button" color={"#EFEFEF"}>
								<IonIcon
									style={{
										fontSize: "2rem",
										color: "#9B9B9B",
										display: "block",
									}}
									icon={documentAttachOutline}
								/>

								<div style={{ display: "hidden" }}>
									<input
										type="file"
										accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf"
										multiple
										onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
											const files = e.target.files;
											if (!files || !projectRef || !assetId || !recordId) return;
											setLoading({});
											try {
												const localFiles: LocalFile[] = [];
												for (const [idx, file] of Array.from(files).entries()) {
													const ext = file.name.split(".").slice(-1)[0];
													const filepath = buildFilePath(projectRef, assetId, recordId, idx, ext);
													const localFile = new LocalFile({
														filepath: filepath,
														formpath: `${historySlice.absolutePath}.${field.name}`,
														filename: file.name,
														extension: ext || "",
														date_uploaded: new Date().toISOString(),
														file,
														project_ref: projectRef,
														asset_id: assetId,
														record_id: recordId,
														size: file.size,
													});
													localFiles.push(localFile);
												}
												await uploadFiles(
													localFiles,
													(bytes, total, identifier) =>
														setLoading((prev) => ({
															...prev,
															[identifier || "base"]: { progress: bytes, total: total || bytes },
														})),
													abortController.signal,
												).catch(async (err) => {
													if (err.message === "canceled") throw err;
													console.warn(`Error eagerly uploading files`);
													console.warn(err);
													await Promise.all(localFiles.map((file) => file.save()));
												});
												fieldRenderProps.onChange(
													[...((fieldRenderProps.value as IFile[]) || []), ...localFiles].map((file) => ({
														filepath: file.filepath,
														formpath: file.formpath,
														filename: file.filename,
														extension: file.extension,
														date_uploaded: file.date_uploaded,
													})),
												);
												// eslint-disable-next-line @typescript-eslint/no-explicit-any
											} catch (err: any) {
												setLoading(undefined);
												if (err.message !== "canceled") {
													console.error(err);
													await presentAlert(uploadingError, [{ text: "OK", role: "cancel" }]);
												}
											} finally {
												setLoading(undefined);
												// This should reset the form input
												const input = e.target;
												input.value = "";
												if (!/safari/i.test(navigator.userAgent)) {
													input.type = "";
													input.type = "file";
												}
											}
										}}
									/>
								</div>
							</IonCard>
						</>
					);
				}}
			/>
			<IonModal
				isOpen={loading !== undefined}
				style={{
					"--width": "fit-content",
					"--min-width": "250px",
					"--max-width": "70vw",
					"--height": "fit-content",
				}}
			>
				<div style={{ display: "flex", flexDirection: "column", gap: "1rem", margin: "1rem" }}>
					<IonLabel>{uploadingMsg}</IonLabel>
					<IonProgressBar
						type={Object.values(loading || {}).length ? "determinate" : "indeterminate"}
						value={getOverallProgress()}
					/>
					<IonButton
						fill="clear"
						style={{ alignSelf: "end", textTransform: "uppercase" }}
						onClick={() => abortController.abort()}
					>
						{t("cancel")}
					</IonButton>
				</div>
			</IonModal>
		</>
	);
};
export default FilePrompt;
