import { IonItem, IonLabel } from "@ionic/react";
import { FormField } from "models/Form";
import React from "react";
import { useSmartFieldCtx } from "../hooks/useSmartForm";

interface DisclaimerProps {
	field: FormField<string>;
}

export const Disclaimer: React.FC<DisclaimerProps> = (props) => {
	const { field } = props;

	const { dig } = useSmartFieldCtx(field);

	const { relevant } = dig;

	return (
		<>
			{relevant && (
				<div
					className="Disclaimer"
					id={`field-${field.name}`}
					style={{
						backgroundColor: "#f9f9f9",
					}}
				>
					<IonItem lines="none">
						<IonLabel
							data-testid={`${field.type}.value`}
							style={{
								fontStyle: "italic",
								color: "var(--ion-color-medium)",
								whiteSpace: "pre-line",
							}}
						>
							{field.label}
						</IonLabel>
					</IonItem>
				</div>
			)}
		</>
	);
};
