import React from "react";

import { IonIcon, IonList } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import { FieldType, FormField } from "models/Form";
import { Button } from "../../../Button";
import { getChildrenAndGrandChildren } from "../../utils/getChildrenAndGrandChildren";
import { FormFieldFactory } from "../../utils/FormFieldFactory";

interface IProps {
	childrenFields: FormField[];
	siblingTypes: FieldType[];
	handleSave: (exit?: boolean, stay?: boolean) => void;
	isFrozen: boolean;
}
type PropsType = IProps;

const FormPageContent: React.FC<PropsType> = (props) => {
	const { t, i18n } = useTranslation();
	const { childrenFields, isFrozen, siblingTypes, handleSave } = props;

	return (
		<>
			<IonList
				style={{
					display: "flex",
					flex: "1",
					flexDirection: "column",
					justifyContent: "space-between",
					paddingTop: "0rem",
				}}
			>
				<div>
					{childrenFields.map((f: FormField) => (
						<FormFieldFactory
							key={f.name}
							field={f}
							parentType={f.type}
							level={0}
							siblingTypes={siblingTypes}
							childrenTypes={getChildrenAndGrandChildren(childrenFields)}
						/>
					))}
					<div style={{ height: "6rem" }} />
				</div>
			</IonList>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					paddingBottom: "2rem",
					paddingInline: "1rem",
					background: "var(--ion-background-color)",
				}}
				data-testid="saveAndCloseDiv"
			>
				<Button disabled={isFrozen} onClickFunction={() => handleSave(false, false)} variant="filled">
					<IonIcon
						style={{
							color: "white",
							marginRight: ".5rem",
						}}
						icon={arrowBack}
						size="small"
					/>
					{i18n.format(t("close_save"), "capitalize")}
				</Button>
			</div>
		</>
	);
};

export default FormPageContent;
