import React, { memo, PropsWithChildren } from "react";

import { IonSelect, IonSelectOption, IonItem, IonLabel, IonNote, IonIcon, IonText } from "@ionic/react";
import { Controller } from "react-hook-form";

import { FieldChoice, FormField } from "models/Form";
import { Divider } from "components/common/Form/components/Divider";

import { alertCircleOutline, informationCircleOutline } from "ionicons/icons";
import { useAppSelector } from "store";
import { useSmartFieldCtx } from "../hooks/useSmartForm";

interface IProps {
	field: FormField<string[]>;
}

type PropsType = IProps;
const MultiSelect: React.FC<PropsType> = (props) => {
	const { field } = props;

	const isFrozen = useAppSelector((state) => state.form.isFrozen);
	const { dig, control } = useSmartFieldCtx(field);

	const { name, relevant, required, hardRequired, disabled, choices, alert } = dig;

	const validChoices = choices || [];
	if (field.sortChoices === "ascending") validChoices.sort((a, b) => a.label.localeCompare(b.label));
	else if (field.sortChoices === "descending") validChoices.sort((a, b) => b.label.localeCompare(b.label));

	const filterOutInvalidSelections = (currentValue: null | string[], validChoices: FieldChoice[]) => {
		// Special case when we are already in a null value (unselected), no need to do anything
		if (!currentValue || currentValue.length === 0) return { requiresChange: false, filtered: currentValue };

		// When something is already selected
		let requiresChange = false;
		const validValues = validChoices.map((v) => v.value);
		const filtered = currentValue.filter((value) => {
			const isValid = validValues.includes(value?.toString() ?? "");
			if (!isValid) requiresChange = true;
			return isValid;
		});
		return { requiresChange, filtered };
	};

	return (
		<div
			className="Input"
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{relevant && (
				<Controller
					name={name}
					control={control}
					rules={{
						required: hardRequired && relevant && validChoices.length !== 0,
					}}
					defaultValue={field.defaultValue as string[]}
					render={({ field: fieldRenderProps }) => {
						// Timeout prevents a form re-render before first render finishes
						setTimeout(() => {
							const value = fieldRenderProps.value as string[];
							const { filtered, requiresChange } = filterOutInvalidSelections(value, validChoices);
							// Auto-select only possible choice when there's just one available
							if (validChoices.length === 1 && (!value || value.length !== 1 || value[0] !== validChoices[0].value)) {
								fieldRenderProps.onChange([validChoices[0].value]);
								// Filter out choices that are no longer valid from our initial selection
							} else if (requiresChange) {
								fieldRenderProps.onChange(filtered);
							}
						}, 0);

						return (
							<IonItem lines="none" disabled={isFrozen || disabled || validChoices.length === 0}>
								<IonLabel className="ion-text-wrap" position="stacked" mode="ios">
									{field.label}
									{(required || hardRequired) && <span style={{ color: "red" }}>&nbsp;*</span>}
								</IonLabel>
								<IonSelect
									aria-label={field.label}
									label=""
									labelPlacement="stacked"
									data-testid={`${field.type}:input`}
									// React Hook Form Managed
									ref={fieldRenderProps.ref}
									name={fieldRenderProps.name}
									value={fieldRenderProps.value}
									onIonBlur={fieldRenderProps.onBlur}
									onIonChange={(e) => {
										setTimeout(() => {
											if (!e.detail.value) return fieldRenderProps.onChange(e);
											// When multiple = true it throws one event with the data in
											// string form (","-separated) and another one with the data in array format
											else if (Array.isArray(e.detail.value)) {
												if (e.detail.value?.toString() !== fieldRenderProps.value?.toString()) {
													fieldRenderProps.onChange(e.detail.value);
												}
											}
										}, 0);
									}}
									// Other config
									placeholder={field.description}
									multiple={true}
								>
									{validChoices.map((choice, idx) => (
										<IonSelectOption
											data-testid={`${field.type}:option:${choice.value}`}
											key={`${field.name}-option-${idx}`}
											value={choice.value}
										>
											{choice.label}
										</IonSelectOption>
									))}
								</IonSelect>
								<Divider color="var(--ion-color-medium)" />
								{alert && (
									<IonNote
										data-testid={`${field.type}:note`}
										color={field.alertColor}
										style={{
											display: "flex",
											gap: ".25rem",
											alignItems: "start",
											textAlign: "start",
											fontSize: ".7rem",
											margin: ".25rem 0",
										}}
									>
										<IonIcon
											icon={
												field.alertIcon === "informationCircleOutline" ? informationCircleOutline : alertCircleOutline
											}
											color={field.alertColor}
											size="small"
										/>
										<IonText>{alert}</IonText>
									</IonNote>
								)}
							</IonItem>
						);
					}}
				/>
			)}
		</div>
	);
};

const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(MultiSelect, propsAreEqual);
export default Memoized;
