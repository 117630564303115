import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAssetsWithLatestStatus } from "api/assetStatus";
import { getForms } from "api/forms";
import { getProjects } from "api/projects";
import { getRecordSummaries, IRecordSummary } from "api/records";
import { DEBUG_ASSETS, DEBUG_FORMS, DEBUG_PROJECTS, DEBUG_RECORD_SUMMARIES } from "mock/debug";
import Asset from "models/Asset";
import Form from "models/Form";
import FormRecord from "models/FormRecord";
import Project from "models/Project";

export interface BackgroundSyncInput {
	projectRef?: string;
	assetId?: string;
}
export interface BackgroundSyncOutput {
	remoteSummaries: IRecordSummary[];
	projectRef?: string;
	assetId?: string;
}

export const performBackgroundSync = async (payload: BackgroundSyncInput): Promise<BackgroundSyncOutput> => {
	const { projectRef, assetId } = payload;

	console.log("Performing background sync", payload);

	// Fetch local stuff
	const localProjects = await getRelevantLocalProjects(projectRef);
	const localAssets = await getRelevantLocalAssets(projectRef, assetId);
	const localForms = await getRelevantLocalForms(projectRef);
	const localRecords = await getRelevantLocalRecords(projectRef, assetId);
	const localAssetIds = localAssets.map((a) => a.id);

	// Fetch remote stuff
	const projectsQuery = getProjects(projectRef ? [projectRef] : undefined);
	const assetsQuery = queryRelevantAssets({ projectRef, assetId, localAssetIds });
	const formsQuery = getForms(projectRef ? [projectRef] : undefined);
	const recordSummariesQuery = queryRelevantSummaries({ projectRef, assetId, localAssetIds });
	const [projects, assets, forms, recordSummaries] = await Promise.all([
		projectsQuery,
		assetsQuery,
		formsQuery,
		recordSummariesQuery,
	]);

	// Mutate IDB State: Remove deleted stuff
	const remoteProjectRefs = [...projects.map((it) => it.ref), ...DEBUG_PROJECTS];
	const projectRefsToDelete = localProjects
		.filter((p) => !remoteProjectRefs.includes(p.ref) && !p.is_deleted)
		.map((p) => p.ref);
	if (projectRefsToDelete.length > 0) {
		console.log("Soft-deleting projects:", projectRefsToDelete);
		Project.deleteMany(projectRefsToDelete);
	}

	const remoteAssetIds = [...assets.map((it) => it.id), ...DEBUG_ASSETS];
	const assetIdsToDelete = localAssets.filter((a) => !remoteAssetIds.includes(a.id) && !a.is_deleted).map((a) => a.id);
	if (assetIdsToDelete.length > 0) {
		console.log("Soft-deleting assets:", assetIdsToDelete);
		Asset.deleteMany(assetIdsToDelete);
	}

	const remoteFormIds = [...forms.map((it) => it.id), ...DEBUG_FORMS];
	const formIdsToDelete = localForms.filter((f) => !remoteFormIds.includes(f.id) && !f.is_deleted).map((f) => f.id);
	if (formIdsToDelete.length > 0) {
		console.log("Soft-deleting forms:", formIdsToDelete);
		Form.deleteMany(formIdsToDelete);
	}

	// Mutate IDB State: Apply changes to simple stuff
	await Project.setMany(projects);
	await Asset.setMany(assets).catch((err) => console.error(err));
	await Form.setMany(forms);

	// Mutate IDB State: Handle records (which are more complex)
	const remoteRecordSummaries = [...recordSummaries, ...DEBUG_RECORD_SUMMARIES];
	const localRecordSummaries = await FormRecord.getAll({ includeDeleted: true, includeInactive: true });
	const updatedLocalRecordSummaries: FormRecord[] = [];
	for (const remote of remoteRecordSummaries) {
		const local = localRecordSummaries.find((it) => it.id === remote.id);
		if (!local) continue;
		updatedLocalRecordSummaries.push(
			// Consider updating also ownership params
			new FormRecord({
				...local,
				is_deleted: remote.is_deleted,
				delivery_status: remote.delivery_status,
				active: true,
			}),
		);
	}
	await FormRecord.setMany(updatedLocalRecordSummaries);

	// Mutate IDB State: Deactivate (hide) records that have been remotely hidden (not in summaries)
	const activeRemoteRecordIds = remoteRecordSummaries.map((it) => it.id);
	const recordIdsToDeactivate = localRecords
		.filter((r) => !activeRemoteRecordIds.includes(r.id) && r.active)
		.map((r) => r.id);
	if (recordIdsToDeactivate.length > 0) {
		console.log("Soft-deleting records:", recordIdsToDeactivate);
		FormRecord.deactivateMany(recordIdsToDeactivate);
	}

	console.log("Background sync succeded");
	return { projectRef, assetId, remoteSummaries: recordSummaries };
};

export const performBackgroundSyncThunk = createAsyncThunk("remote/backgroundSync", performBackgroundSync);

type QueryRelevantRemoteProps = {
	projectRef?: string;
	assetId?: string;
	localAssetIds: string[];
};

const queryRelevantAssets = async (props: QueryRelevantRemoteProps) => {
	const { projectRef, assetId, localAssetIds } = props;

	// Query specific asset
	if (projectRef && assetId) {
		return await getAssetsWithLatestStatus({
			projectRefs: [projectRef],
			assetIds: [assetId],
			includeDeleted: true,
		}).then((res) => res.items);
	}
	return await getAssetsWithLatestStatus({ assetIds: localAssetIds, includeDeleted: true }).then((res) => res.items);
};

const queryRelevantSummaries = async (props: QueryRelevantRemoteProps) => {
	const { projectRef, assetId, localAssetIds } = props;

	// Query specific asset
	if (projectRef && assetId) {
		return await getRecordSummaries({ projectRefs: [projectRef], assetIds: [assetId] });
	}

	return await getRecordSummaries({ assetIds: localAssetIds });
};

const getRelevantLocalProjects = async (projectRef?: string) => {
	if (projectRef) {
		try {
			const project = await Project.get(projectRef, true);
			return [project];
		} catch (_) {
			return [];
		}
	}
	return Project.getAll(true);
};
const getRelevantLocalAssets = async (projectRef?: string, assetId?: string) => {
	if (assetId) {
		try {
			const asset = await Asset.get(assetId, true);
			return [asset];
		} catch (_) {
			return [];
		}
	} else if (projectRef) {
		return Asset.byProjectRef(projectRef, true);
	}
	return Asset.getAll(true);
};
const getRelevantLocalForms = async (projectRef?: string) => {
	if (projectRef) {
		const project = await Project.get(projectRef, true);
		return Form.getMany(project.configuration.form_ids, true);
	}
	return Form.getAll(true);
};

const getRelevantLocalRecords = async (projectRef?: string, assetId?: string) => {
	if (assetId) {
		return FormRecord.byAssetId(assetId, { includeDeleted: true, includeInactive: true });
	} else if (projectRef) {
		return FormRecord.byProjectRef(projectRef, { includeDeleted: true, includeInactive: true });
	}
	return FormRecord.getAll({ includeDeleted: true, includeInactive: true });
};
