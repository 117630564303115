import React, { memo, PropsWithChildren, useEffect, useRef, useState } from "react";

import { IonItem, IonLabel, IonIcon, IonInput, IonButton, useIonAlert } from "@ionic/react";
import { locateOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import { FormField } from "models/Form";

import { useAppSelector } from "store";
import { useSmartFieldCtx } from "../hooks/useSmartForm";

interface IProps {
	field: FormField<string>;
}

type PropsType = IProps;
const Location: React.FC<PropsType> = (props) => {
	const { t, i18n } = useTranslation();
	const { field } = props;

	const isFrozen = useAppSelector((state) => state.form.isFrozen);
	const { dig, control } = useSmartFieldCtx(field);

	const { name, relevant, required, hardRequired, disabled } = dig;

	const [presentAlert] = useIonAlert();

	const parseCoordinates = (geojsonString?: unknown) => {
		if (!geojsonString) return [undefined, undefined];

		if (typeof geojsonString === "string")
			try {
				const geojson = JSON.parse(geojsonString);
				return geojson?.coordinates || [undefined, undefined];
			} catch (err) {
				// Do nothing
			}

		if (typeof geojsonString === "object" && "coordinates" in geojsonString)
			return (geojsonString as { coordinates: [number, number] }).coordinates;

		return [undefined, undefined];
	};

	const validateCoordinates = (longitude: number | undefined, latitude: number | undefined) => {
		if (longitude === undefined || latitude === undefined) return undefined;
		return `{"bbox":[0],"type": "Point","coordinates": [${longitude}, ${latitude}]}`;
	};

	return (
		<div
			id={`field-${field.name}`}
			style={{
				backgroundColor: "#f9f9f9",
			}}
		>
			{relevant && (
				<IonItem lines="none">
					<IonLabel
						className="ion-text-wrap capitalize-first-letter"
						position="stacked"
						mode="ios"
						style={{
							opacity: isFrozen || disabled ? 0.3 : 1,
						}}
					>
						{field.label}
						{(required || hardRequired) && <span style={{ color: "red" }}>&nbsp;*</span>}
					</IonLabel>
					<div
						style={{
							display: "flex",
							width: "100%",
							marginInline: "unset",
						}}
					>
						<Controller
							name={name}
							control={control}
							rules={{
								required: hardRequired && relevant,
							}}
							render={({ field: fieldRenderProps }) => {
								const [current, setCurrent] = useState<(number | undefined)[]>(
									parseCoordinates(fieldRenderProps.value as string),
								);
								const longitudeInputRef = useRef<HTMLIonInputElement>(null);
								const latitudeInputRef = useRef<HTMLIonInputElement>(null);

								useEffect(() => {
									const validated = validateCoordinates(current[0], current[1]);
									// Perform simple string comparison to check if there has been a meaningful change
									if (fieldRenderProps.value !== validated) {
										fieldRenderProps.onBlur();
										fieldRenderProps.onChange(validated);
									}
								}, [current]);

								const getLocation = () => {
									navigator.geolocation.getCurrentPosition(
										(result) => {
											const { longitude, latitude } = result.coords;
											setCurrent([Math.round(longitude * 1e7) / 1e7, Math.round(latitude * 1e7) / 1e7]);
										},
										(error) => {
											console.error(error);
											switch (error.code) {
												case error.PERMISSION_DENIED:
													return presentAlert(i18n.format(t("error_location_permission_denied"), "capitalize"));
												case error.POSITION_UNAVAILABLE:
													return presentAlert(i18n.format(t("error_location_position_unavailable"), "capitalize"));
												case error.TIMEOUT:
													return presentAlert(i18n.format(t("error_location_position_timeout"), "capitalize"));
												default:
													return presentAlert(i18n.format(t("error_location_position_unknown"), "capitalize"));
											}
										},
									);
								};

								return (
									<div
										ref={fieldRenderProps.ref}
										style={{
											display: "flex",
											width: "100%",
											alignItems: "center",
											gap: "1rem",
										}}
									>
										<IonButton
											data-testid={`${field.type}:gps`}
											fill="solid"
											color="secondary"
											size="default"
											disabled={isFrozen || disabled}
											onClick={getLocation}
											style={{
												"--padding-start": 0,
												"--padding-end": 0,
												aspectRatio: "1",
											}}
										>
											<IonIcon icon={locateOutline} size="small" />
										</IonButton>
										<IonItem
											className={current[0] === undefined ? "ion-invalid" : undefined}
											style={{ "--inner-padding-start": 0, "--inner-padding-end": 0, flex: 1, maxWidth: "10rem" }}
											disabled={isFrozen || disabled}
										>
											<IonLabel position="stacked">{`${i18n.format(t("longitude"), "capitalize")}`}</IonLabel>
											<IonInput
												aria-label={`${field.label}-longitude`}
												data-testid={`${field.type}:input:longitude`}
												ref={longitudeInputRef}
												type="number"
												value={current[0]}
												onIonChange={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setCurrent((curr) => [
														!e.detail.value ? undefined : Math.max(Math.min(Number(e.detail.value) || 0, 90), -90),
														curr[1],
													]);
												}}
												onKeyDown={(evt) => ["e", "E"].includes(evt.key) && evt.preventDefault()}
												min={-90}
												max={90}
												step={"1e-12"}
											/>
										</IonItem>
										<IonItem
											className={current[1] === undefined ? "ion-invalid" : undefined}
											style={{ "--inner-padding-start": 0, "--inner-padding-end": 0, flex: 1, maxWidth: "10rem" }}
											disabled={isFrozen || disabled}
										>
											<IonLabel position="stacked">{`${i18n.format(t("latitude"), "capitalize")}`}</IonLabel>
											<IonInput
												aria-label={`${field.label}-latitude`}
												data-testid={`${field.type}:input:latitude`}
												ref={latitudeInputRef}
												type="number"
												value={current[1]}
												onIonChange={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setCurrent((curr) => [
														curr[0],
														!e.detail.value ? undefined : Math.max(Math.min(Number(e.detail.value) || 0, 90), -90),
													]);
												}}
												onKeyDown={(evt) => ["e", "E"].includes(evt.key) && evt.preventDefault()}
												min={-90}
												max={90}
												step={"1e-12"}
											/>
										</IonItem>
									</div>
								);
							}}
						/>
					</div>
				</IonItem>
			)}
		</div>
	);
};
const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(Location, propsAreEqual);
export default Memoized;
