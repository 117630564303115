import { dbPromise } from "../services/idb";

export type Expression = string;

interface IRawLocalFile {
	filepath: string;
	formpath: string;
	filename: string;
	extension: string;
	date_uploaded: string;
	file?: File;
	size: number;
	record_id: string;
	asset_id: string;
	project_ref: string;
}
export default class LocalFile implements IRawLocalFile {
	filepath: string;
	formpath: string;
	filename: string;
	extension: string;
	date_uploaded: string;
	file?: File;
	size: number;
	record_id: string;
	asset_id: string;
	project_ref: string;

	constructor(localImage: IRawLocalFile) {
		this.filepath = localImage.filepath;
		this.formpath = localImage.formpath;
		this.filename = localImage.filename;
		this.extension = localImage.extension;
		this.date_uploaded = localImage.date_uploaded;
		this.file = localImage.file;
		this.size = localImage.size;
		this.record_id = localImage.record_id;
		this.asset_id = localImage.asset_id;
		this.project_ref = localImage.project_ref;
	}

	static async getAllKeys() {
		return await (await dbPromise).getAllKeys("files");
	}

	static async keysByRecordId(record_id: string) {
		return await (await dbPromise).getAllKeysFromIndex("files", "record_id", record_id);
	}

	static async get(id: string) {
		return await (await dbPromise).get("files", id).then((r) => {
			if (r === undefined) throw new Error(`Key ${id} not found`);
			return new LocalFile(r);
		});
	}

	static async getMany(ids: string[]) {
		const tx = (await dbPromise).transaction("files", "readwrite");
		const store = tx.objectStore("files");
		const data = await Promise.all(ids.map((id) => store.get(id)));
		await tx.done;
		return data.filter((it): it is LocalFile => !it);
	}

	static async byRecordId(record_id: string): Promise<LocalFile[]> {
		return await (await dbPromise)
			.getAll("files")
			.then((r) => r.filter((it) => it.record_id === record_id).map((ri) => new LocalFile(ri)));
	}

	static async getAll() {
		return await (await dbPromise).getAll("files").then((r) => r.map((ri) => new LocalFile(ri)));
	}

	static async set(data: LocalFile) {
		await (await dbPromise).put("files", data);
		return new LocalFile(data);
	}

	static async setMany(data: LocalFile[]) {
		const tx = (await dbPromise).transaction("files", "readwrite");
		const store = tx.objectStore("files");
		await Promise.all(data.map((it) => store.put(it)));
		await tx.done;
	}

	static async delete(data: Pick<LocalFile, "filepath">) {
		return (await dbPromise).delete("files", data.filepath);
	}

	static async deleteMany(data: Pick<LocalFile, "filepath">[]) {
		return await Promise.all(data.map((file) => LocalFile.delete(file)));
	}

	delete() {
		return LocalFile.delete(this);
	}

	save() {
		return LocalFile.set(this);
	}
}
