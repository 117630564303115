import { IonIcon, IonSpinner } from "@ionic/react";
import { checkmarkCircleOutline, ellipseOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";

import Form from "models/Form";
import FormRecord from "models/FormRecord";
import { useParams } from "react-router-dom";
import { FormEngine } from "@arup-group/dhub-forms-engine";

export const CompletitionStatusIcon: React.FC<{
	recordId: string;
	records: FormRecord[];
	style?: Record<string, string>;
}> = (props) => {
	const { recordId, records, style } = props;
	const { projectRef, assetId } = useParams<{
		projectRef: string;
		assetId: string;
	}>();
	const [record, setRecord] = useState(records?.find((it) => it.id === recordId));

	const checkCompletition = async (record: FormRecord) => {
		console.log(
			"CompletitionStatusIcon - Because record.completed is undefined/null, we are checking if it is completed",
		);
		const form = await Form.get(record.form_id);
		const engine = new FormEngine(projectRef, assetId, form, [record, ...records]);
		return engine.getIsCompleted(undefined);
	};

	useEffect(() => {
		if (record && (record.completed === undefined || record.completed === null)) {
			checkCompletition(record).then((completed) => {
				FormRecord.get(record.id).then((record) => {
					const newRecord = new FormRecord({ ...record, completed });
					FormRecord.set(newRecord);
					setRecord(newRecord);
				});
			});
		}
	}, [record?.completed]);

	if (record?.completed === undefined)
		return (
			<IonSpinner
				name="lines-sharp-small"
				color="medium"
				style={{
					height: "1.25rem",
					width: "1.25rem",
					...style,
				}}
			/>
		);
	const fillColor = record?.completed ? "var(--ion-color-success)" : "#CBD5E0";
	const svg = record?.completed ? checkmarkCircleOutline : ellipseOutline;
	return (
		<IonIcon
			slot="end"
			size="small"
			style={{
				color: fillColor,
				height: "1.25rem",
				width: "1.25rem",
				...style,
			}}
			icon={svg}
		/>
	);
};
