import axios, { AxiosPromise, InternalAxiosRequestConfig } from "axios";

import config from "config";
import { loginRequest, msalInstance } from "config/msalConfig";

export const apiClient = axios.create({
	baseURL: config.API,
	params: {},
	headers: {},
});

export const attachTokenInterceptor = async (request: InternalAxiosRequestConfig) => {
	if (request.headers) {
		request.headers["Authorization"] = `Bearer ${
			(await msalInstance.acquireTokenSilent(loginRequest).catch(console.warn))?.idToken
		}`;
	}
	return request;
};

console.log("Adding default cognito interceptor");
apiClient.interceptors.request.use(attachTokenInterceptor);

export type CustomAxiosRequest<I, O> = (args: I) => AxiosPromise<O>;
