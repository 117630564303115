import React, { PropsWithChildren } from "react";

interface NoteProps extends PropsWithChildren {
	variant: "green" | "yellow" | "red";
}

export const Note: React.FC<NoteProps> = (props: NoteProps) => {
	const styles = {
		green: {
			backgroundColor: "#e4fde3",
			color: "#4ba046",
		},
		yellow: {
			backgroundColor: "#fffaa8",
			color: "#e1a900",
		},
		red: {
			backgroundColor: "#ffeeef",
			color: "#e61e28",
		},
	};
	return (
		<div
			style={{
				...styles[props.variant],
				width: "100%",
				height: "2rem",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				fontSize: "0.875rem",
				borderRadius: "0.25rem",
				textTransform: "capitalize",
				margin: "4px 2px",
			}}
		>
			{props.children}
		</div>
	);
};
