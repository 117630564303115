import { IRecordSummary } from "api/records";
import LocalFile from "models/LocalFile";
import LocalImage from "models/LocalImage";
import FormRecord from "../../models/FormRecord";
import { jsonSize } from "./size";

export const getSizes = async (records: FormRecord[], photos?: LocalImage[], files?: LocalFile[]) => {
	return {
		data: jsonSize(records),
		files: files === undefined ? NaN : files.reduce((prev, next) => prev + (next?.size || 0), 0),
		photos: photos === undefined ? NaN : photos.reduce((prev, next) => prev + (next?.size || 0), 0),
	};
};

export const getRecordsPendingUpload = async (
	remoteSummary: IRecordSummary[],
	projectRef: string,
	assetId?: string,
) => {
	const allRecords = await (assetId
		? FormRecord.byAssetId(assetId, { includeDeleted: true, includeInactive: true })
		: FormRecord.byProjectRef(projectRef, { includeDeleted: true, includeInactive: true }));
	return allRecords.filter((record) => {
		const remote = remoteSummary.find((it) => it.id === record.id);
		if (!remote) return false;
		if (!record.date_synced) return true;
		return record.date_updated.getTime() > record.date_synced.getTime();
	});
};

export const getAllPendingUpload = async (
	remoteSummary: IRecordSummary[],
	projectRef: string,
	assetId?: string,
) => {
	const records = await getRecordsPendingUpload(remoteSummary, projectRef, assetId);
	const photos = (await Promise.all(records.map((it) => LocalImage.byRecordId(it.id)))).flat();
	const files = (await Promise.all(records.map((it) => LocalFile.byRecordId(it.id)))).flat();

	return {
		records,
		photos,
		files,
		sizes: await getSizes(records, photos, files),
	};
};
