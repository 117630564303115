import IWithPagination from "interfaces/IWithPagination";
import Asset, { IRawAsset } from "models/Asset";

import { apiClient } from ".";

export interface DataFilter {
	formId: string;
	fieldPath: string;
	values: string[];
	isArray?: boolean;
}

export interface FilterAssetProps {
	dataFilters: DataFilter[];
	projectRefs?: string[];
	assetIds?: string[];
	status?: string;
	excludeProjectRefs?: string[];
	excludeAssetIds?: string[];
	excludeStatus?: string;
	includeDeleted?: boolean;
	page?: number;
}
export const filterAssets = async (props: FilterAssetProps): Promise<IWithPagination<Asset>> => {
	const {
		dataFilters,
		projectRefs,
		assetIds,
		status,
		excludeProjectRefs,
		excludeAssetIds,
		excludeStatus,
		includeDeleted = false,
		page = 1,
	} = props;
	const params: Record<string, string | boolean | number> = {
		order: "name.asc",
	};
	if (projectRefs) params["projectRefs"] = projectRefs.join(",");
	if (assetIds) params["assetIds"] = assetIds.join(",");
	if (status) params["status"] = status;
	if (excludeProjectRefs) params["excludeProjectRefs"] = excludeProjectRefs.join(",");
	if (excludeAssetIds) params["excludeAssetIds"] = excludeAssetIds.join(",");
	if (excludeStatus) params["excludeStatus"] = excludeStatus;
	if (includeDeleted) params["includeDeleted"] = includeDeleted;
	if (page) params["page"] = page;
	const filterResponse = await apiClient
		.post<IWithPagination<IRawAsset>>(`forms/filter/assets`, dataFilters, { params })
		.then((r) => r.data);
	return {
		pagination: filterResponse.pagination,
		data: filterResponse.data.map((it) => new Asset(it)),
	};
};
