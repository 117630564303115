import { useEffect, useState } from "react";

import { loginRequest, msalInstance } from "config/msalConfig";
import { useMsal } from "@azure/msal-react";

const useToken = () => {
	const [token, setToken] = useState<string>();
	const { accounts } = useMsal();

	// TODO: Esto probablemente no está refrescando el token
	// si se caduca mientras el componente sigue montado...
	useEffect(() => {
		msalInstance
			.acquireTokenSilent(loginRequest)
			.catch(() => undefined)
			.then((token) => token?.idToken)
			.then(setToken);
	}, [accounts.length]);

	return token;
};

export default useToken;
