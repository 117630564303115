import React from "react";

import { IImage } from "interfaces/IImage";
import { IFile } from "interfaces/IFile";

import { FormField } from "../../../../models/Form";
import { FormValues } from "../../../../models/FormRecord";
import Calculation from "../widgets/Calculation";
import { DrillDownGroup } from "../widgets/DrillDownGroup";
import Images from "../widgets/Images";
import InlineGroup from "../widgets/InlineGroup";
import Input from "../widgets/Input";
import Location from "../widgets/Location";
import MultiRadioGroup from "../widgets/MultiRadioGroup";
import MultiSelect from "../widgets/MultiSelect";
import RadioGroup from "../widgets/RadioGroup";
import { RepeatableGroup } from "../widgets/RepeatableGroup";
import Select from "../widgets/Select";
import TextArea from "../widgets/TextArea";
import Sketches from "../widgets/Sketches";
import Files from "../widgets/Files";
import Drawings from "../widgets/Drawings";

import { Disclaimer } from "components/common/Form/widgets/Disclaimer";
import { Link } from "components/common/Form/widgets/Link";
import { IDrawingRef } from "interfaces/IDrawingRef";

interface IProps {
	field: FormField;
	parentType?: string;
	level?: number;
	siblingTypes?: string[];
	childrenTypes?: string[]; // This includes children and cousins.
	parentInlineIds?: string[];
}
export const FormFieldFactory: React.FC<IProps> = (props) => {
	const { field, parentType, level, siblingTypes, childrenTypes, parentInlineIds = [] } = props;

	let variant: "dark" | "gray" | "white" = "dark";
	const allSiblingsAreDDG = siblingTypes?.filter((it) => it === "drillDownGroup").length === siblingTypes?.length;
	const allSiblingsAreDDGOrRepeatables =
		siblingTypes?.filter((it) => it === "drillDownGroup" || it === "repeatableGroup").length === siblingTypes?.length;
	const parentIsGroup = parentType?.includes("Group");
	const atLeastOneChildIsGroup: boolean =
		(childrenTypes?.filter((it) => it.includes("Group")).length || 0) >
		(siblingTypes?.filter((it) => it.includes("Group")).length || 0);
	switch (field.type) {
		case "link":
			return <Link key={field.name} field={field as FormField<string>} />;
		case "disclaimer":
			return <Disclaimer key={field.name} field={field as FormField<string>} />;
		case "radio":
			return field.multiple ? (
				<MultiRadioGroup key={field.name} field={field as FormField<string[]>} />
			) : (
				<RadioGroup key={field.name} field={field as FormField<string>} />
			);
		case "dropdown":
			return field.multiple ? (
				<MultiSelect key={field.name} field={field as FormField<string[]>} />
			) : (
				<Select key={field.name} field={field as FormField<string>} />
			);
		case "textarea":
			return <TextArea key={field.name} field={field as FormField<string>} />;
		case "calculation":
			return <Calculation key={field.name} field={field as FormField<string>} />;
		case "location":
			return <Location key={field.name} field={field as FormField<string>} />;
		case "images":
			return <Images key={field.name} field={field as FormField<IImage[]>} />;
		case "sketch":
			return <Sketches key={field.name} field={field as FormField<IImage[]>} />;
		case "files":
			return <Files key={field.name} field={field as FormField<IFile[]>} />;
		case "drawings":
			return <Drawings key={field.name} field={field as FormField<IDrawingRef>} />;
		case "inlineGroup":
			variant =
				(parentIsGroup && !atLeastOneChildIsGroup) || siblingTypes?.includes("repeatableGroup") ? "gray" : "dark";
			return (
				<InlineGroup
					key={field.name}
					field={field as FormField<FormValues>}
					level={level ? level + 1 : 1}
					variant={variant}
					parentInlineIds={parentInlineIds}
				/>
			);
		case "drillDownGroup":
			variant = "dark";
			if (allSiblingsAreDDG) {
				variant = "white";
			} else if (allSiblingsAreDDGOrRepeatables) {
				variant = "gray";
			} else if (parentIsGroup && atLeastOneChildIsGroup) {
				if (parentType === "drillDownGroup" && siblingTypes?.includes("inlineGroup")) {
					variant = "dark";
				} else {
					variant = "gray";
				}
			} else if ((parentIsGroup && !atLeastOneChildIsGroup) || allSiblingsAreDDGOrRepeatables) {
				variant = "gray";
			}
			return (
				<DrillDownGroup
					key={field.name}
					field={field as FormField<FormValues>}
					variant={variant}
					parentInlineIds={parentInlineIds}
				/>
			);
		case "repeatableGroup":
			return (
				<RepeatableGroup key={field.name} field={field as FormField<FormValues[]>} parentInlineIds={parentInlineIds} />
			);
		default:
			return <Input key={field.name} field={field as FormField<string>} />;
	}
};
