import { getSignedCookies } from "api/signedUrl";
import React from "react";
import Cookies from "universal-cookie";

interface IProps {
	assetId: string;
	onCookieSet?: (assetId?: string) => void;
}

const CookieProvider: React.FC<IProps> = (props) => {
	const { assetId, onCookieSet } = props;

	const cookieManager = new Cookies();

	const feedCookieMonster = async (assetId: string) => {
		const res = await getSignedCookies({ assetId }).catch(() => undefined);
		if (!res) return;
		const { paths, cookies } = res;
		const nowPlusOneHour = new Date();
		nowPlusOneHour.setTime(nowPlusOneHour.getTime() + 3600e3);
		const expiryDate = cookies["CloudFront-Expires"] ? new Date(cookies["CloudFront-Expires"] * 1000) : nowPlusOneHour;
		const domain = window.location.hostname;
		for (const path of paths) {
			cookieManager.set("CloudFront-Key-Pair-Id", cookies["CloudFront-Key-Pair-Id"], {
				path,
				expires: expiryDate,
				domain,
				sameSite: "lax",
				secure: true,
			});
			cookieManager.set("CloudFront-Signature", cookies["CloudFront-Signature"], {
				path,
				expires: expiryDate,
				domain,
				sameSite: "lax",
				secure: true,
			});
			cookies["CloudFront-Expires"] &&
				cookieManager.set("CloudFront-Expires", cookies["CloudFront-Expires"], {
					path,
					expires: expiryDate,
					domain,
					sameSite: "lax",
					secure: true,
				});
			cookies["CloudFront-Policy"] &&
				cookieManager.set("CloudFront-Policy", cookies["CloudFront-Policy"], {
					path,
					expires: expiryDate,
					domain,
					sameSite: "lax",
					secure: true,
				});
		}
		onCookieSet && onCookieSet(assetId);
	};

	React.useEffect(() => {
		if (["debug-1", "debug-2"].includes(assetId)) return;
		const INTERVAL_SECONDS = 3600 - 60; // This should ask for new cookies 1 min before it goes bad
		feedCookieMonster(assetId);
		const interval = setInterval(() => assetId && feedCookieMonster(assetId), INTERVAL_SECONDS * 1e3);
		return () => clearInterval(interval);
	}, [assetId]);

	return <React.Fragment />;
};

export default CookieProvider;
