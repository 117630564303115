import { useEffect } from "react";

import { useIonToast } from "@ionic/react";
import { FieldError, FieldErrors, FieldValues, FormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Form from "models/Form";
import { IDataValue } from "models/FormRecord";
import { doRecursively } from "@arup-group/dhub-forms-engine";

export const useFormErrorsCheck = (form: Form, formState: FormState<FieldValues>) => {
	const { t } = useTranslation();
	const [present] = useIonToast();

	useEffect(() => {
		const handleValidationError = (fieldKey: string, fieldError: FieldError) => {
			const fieldLabel = doRecursively<string>({
				fields: form.fields,
				action: ({ field }) => ({ label: field.label }),
				shouldAction: ({ path }) => path[path.length - 1] === fieldKey,
				shouldStop: ({ result }) => result.label !== undefined,
			}).label;
			present({
				position: "top",
				color: "dark",
				message: t("invalid_msg", { field: fieldLabel || fieldKey, reason: fieldError.type }),
				duration: 2000,
				buttons: ["Dismiss"],
			});
			const element = document.querySelector(`#field-${fieldKey}`);
			element?.scrollIntoView(true);
		};

		const recursivelyReviewErrorTree = (fieldErrors: FieldErrors<Record<string, IDataValue>>) => {
			for (const [key, value] of Object.entries(fieldErrors)) {
				if (value && "type" in value && "message" in value && "ref" in value) {
					handleValidationError(key, value as FieldError);
					return;
				} else {
					recursivelyReviewErrorTree(value as FieldErrors);
				}
			}
		};
		if (formState.errors === undefined) return;
		recursivelyReviewErrorTree(formState.errors);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formState.isSubmitting]);
};
