import { get } from "react-hook-form";

import { FormValues } from "../../../../models/FormRecord";
import { FormFieldsWithPath } from "../../../../utils/searchFilterFields";

const evalueValueAgainstFilter = (filterMode: string, filterValue: string, recordValue: string | string[]) => {
	if (Array.isArray(recordValue)) {
		return recordValue.includes(filterValue.toString());
	} else {
		switch (filterMode) {
			case "includes":
				return recordValue.toLowerCase().includes(filterValue.toString().toLowerCase());
			default:
				return recordValue === filterValue;
		}
	}
};
const evaluateRecordAgainstFilters = (
	fieldValues: FormValues,
	filterFields: FormFieldsWithPath[],
	filters: FormValues,
	currentLevel: string,
): boolean => {
	for (const [fieldName, filterValue] of Object.entries(filters)) {
		// If unspecified filter, skip it
		if (filterValue === null || filterValue === undefined) continue;
		// Find field filter mode
		const filterField = filterFields.find((f) => f.name === fieldName);
		// If field not found, skip it
		if (!filterField) continue;

		let filterMode = filterField?.filter;
		if (!filterMode) {
			if (filterField.type === undefined) filterMode = "includes";
			else if (filterField.type === "text") filterMode = "includes";
			else filterMode = "exact";
		}
		const splitPath = filterField.path
			.split(".")
			.filter((it) => isNaN(parseInt(it))) // Sometimes the number of the next repeatable comes up
			.join(".")
			.split(currentLevel);
		const childrenPath = splitPath[1];
		const path = childrenPath ? childrenPath.replace(/^./, "") : filterField.path;

		let issueValue = fieldName === "name" ? fieldValues.name : get(fieldValues, path || fieldName); // Esto con la ruta completa
		// Transform issue value to string type
		issueValue =
			issueValue === undefined || issueValue === null
				? ""
				: Array.isArray(issueValue)
				? issueValue.map((it) => it.toString())
				: issueValue.toString();

		// Evaluate single filter value against issue value
		const result = evalueValueAgainstFilter(filterMode, filterValue.toString(), issueValue);
		// Return with false if a filter value doesn't evalue as a match, else, continue
		if (!result) return false;
	}
	return true;
};

export const applyFilters = (
	items: FormValues[], // Tienen que ser los hijos del repetible
	filterFields: FormFieldsWithPath[],
	filters: FormValues, // Valores de los filtros  {absKey1: value1, absKey2: value2}
	currentLevel: string,
): string[] => {
	return items
		.filter((item) => evaluateRecordAgainstFilters(item, filterFields, filters, currentLevel))
		.map((item) => item.id as string);
};
