import React, { memo, PropsWithChildren, useContext, useState } from "react";

import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";
import { useAppSelector } from "store";

import DeleteItemModal from "./DeleteItemModal";
import RepeatableItem from "./RepeatableItem";
import RepeatableHeader from "./RepeatableHeader";
import { Virtuoso } from "react-virtuoso";
import { IonContent } from "@ionic/react";
import { applyFilters } from "components/modals/FiltersModal/utils/filterUtils";
import { getEmail } from "utils/getEmail";
import { updateRepeatableAdditionalData } from "components/common/Form/components/FormPage/addRepeatableUpdatedAtUpdatedBy";
import { FormContext, useSmartFieldCtx } from "../../hooks/useSmartForm";
import { dotPath } from "@arup-group/dhub-forms-engine";
import { SubmitFormFn } from "views/RecordView/RecordView";

interface IProps {
	field: FormField;
	setFilterModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onSubmit: SubmitFormFn;
	handleDuplicateItem: (e: React.MouseEvent, id: string) => Promise<void>;
}

const RepeatableTable: React.FC<IProps> = (props: IProps) => {
	const { field, setFilterModalIsOpen, onSubmit, handleDuplicateItem } = props;

	const [deleteModalOpenId, setDeleteModalOpenId] = useState<string | number>();
	const { filterFields, filterValues } = useAppSelector((state) => state.filters);
	const fieldReferenceLibrary = useAppSelector((store) => store.form.fieldReferenceLibrary);
	const historySlice = useAppSelector((state) => state.history);
	const repeatableFullPath = historySlice.list.join(".");

	const ctx = useContext(FormContext);

	const { currentValue, items, applyChange } = useSmartFieldCtx(field);

	// When browsing an item of the list, omit the number at the end (item id)
	// for the purposes of this component's history
	const repeatableHistory = Number.isNaN(Number(historySlice.active))
		? historySlice.list
		: historySlice.list.slice(0, -1);

	const handleDeleteItem = async (idOrIdxToDelete: string | number) => {
		if (!ctx) return;
		const user = await getEmail();
		const allRawItems = currentValue as FormValues[];
		let foundIdx: number;
		if (typeof idOrIdxToDelete === "string") {
			foundIdx = allRawItems.findIndex((it) => it.id === idOrIdxToDelete);
		} else {
			// This should not happen normally but it's a way to cover us against
			// potential items that might have no ID (some do due to legacy bugs).
			// This should allow us to delete them reliably
			foundIdx = idOrIdxToDelete;
		}
		const deleteFlagPath = dotPath([repeatableFullPath, `${foundIdx}`, "_is_deleted"]);
		updateRepeatableAdditionalData([`${repeatableFullPath}.${foundIdx}`], fieldReferenceLibrary, applyChange, user);
		applyChange(deleteFlagPath, true);
		await ctx.handleSubmit(async (values) => {
			ctx.resetField(deleteFlagPath, { defaultValue: true });
			await onSubmit(values, [deleteFlagPath], repeatableHistory, {
				exit: false,
				stay: true,
				completed: ctx.engine.getIsCompleted(),
			});
		}, console.error)();
	};

	const filteredIds = Object.values(filterValues).length
		? applyFilters(
				items.map((it) => it.item),
				filterFields,
				filterValues,
				field.name,
		  )
		: items.map((it) => it.item.id);

	const filteredItems = items.filter(({ item }) => filteredIds.includes(item.id as string));

	return (
		<>
			<RepeatableHeader
				field={field}
				filteredCount={filteredItems.length}
				totalCount={items.length}
				setFilterModalIsOpen={setFilterModalIsOpen}
			/>
			<IonContent forceOverscroll={false}>
				<Virtuoso
					style={{ flex: 1 }}
					data={filteredItems}
					increaseViewportBy={800}
					itemContent={(_, { index, refNo, title, subtitle, iconColor, iconSrc, item }) => {
						return (
							<div style={{ height: "3rem", minHeight: "3rem" }}>
								<RepeatableItem
									key={`${field.name}.${item.id}`}
									id={item.id as string}
									refNo={refNo}
									idx={index}
									title={title}
									subtitle={subtitle}
									iconColor={iconColor}
									iconSrc={iconSrc}
									field={field}
									setDeleteOpenModalId={setDeleteModalOpenId}
									handleDuplicateItem={handleDuplicateItem}
								/>
							</div>
						);
					}}
				/>
			</IonContent>
			<DeleteItemModal
				openId={deleteModalOpenId}
				onClose={() => setDeleteModalOpenId(undefined)}
				handleDelete={handleDeleteItem}
			/>
		</>
	);
};

const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(RepeatableTable, propsAreEqual);
export default Memoized;
