import React from "react";

import { InternalAxiosRequestConfig } from "axios";

import { apiClient, attachTokenInterceptor } from "api";
import { Auth } from "../Auth/Auth";

type WithApiKeyProps = {
	apiKey?: string;
};
export const WithApiKeyOrUserAuth = <P extends WithApiKeyProps>(Component: React.FC<P>) => {
	const apiKey = new URLSearchParams(window.location.search).get("api_key") || undefined;

	const apiKeyInterceptor = async (request: InternalAxiosRequestConfig) => {
		if (request.headers) request.headers["Authorization"] = `Api-Key ${apiKey}`;
		return request;
	};

	const HOC = (props: P) => {
		React.useLayoutEffect(() => {
			const interceptor = apiClient.interceptors.request.use(
				apiKey ? apiKeyInterceptor : attachTokenInterceptor,
				Promise.reject,
				{
					runWhen: (r: InternalAxiosRequestConfig) => !hasAuthHeader(r),
				},
			);
			console.log("Added axios interceptor", interceptor);
			return () => {
				console.log("Ejecting axios interceptor", interceptor);
				apiClient.interceptors.request.eject(interceptor);
			};
		}, []);

		if (apiKey)
			return (
				<React.Fragment>
					<Component apiKey={apiKey} {...props} />
				</React.Fragment>
			);
		return (
			<Auth>
				<Component {...props} />
			</Auth>
		);
	};
	HOC.displayName = `WithApiKeyOrUserAuth${Component.displayName}`;
	return HOC;
};

const hasAuthHeader = (r: InternalAxiosRequestConfig) => "Authorization" in (r.headers || {});
