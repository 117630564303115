import { IDrawingRef } from "interfaces/IDrawingRef";
import { IDataValue } from "models/FormRecord";

export const parseDrawing = (value: IDataValue): IDrawingRef | undefined => {
	if (!value) return undefined;
	if (typeof value === "string") {
		// TODO: check when there is a choice available, the value is the name of the choice, not an object
		try {
			return JSON.parse(value);
		} catch (error) {
			console.error("JSON Parsing Error", error);
		}
		return undefined;
	}
	return value as IDrawingRef;
};
