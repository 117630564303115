import { IDataValue } from "models/FormRecord";
import { FieldReferenceLibrary } from "interfaces/FieldReference";
import { DottedFieldPath } from "@arup-group/dhub-forms-engine";

export function updateRepeatableAdditionalData(
	changes: string[],
	fieldReferenceLibrary: FieldReferenceLibrary,
	applyChange: (path: DottedFieldPath, value: IDataValue) => void,
	user: string | undefined,
) {
	const pathsToMarkAsUpdated: Set<string | undefined> = new Set(
		changes
			.map((change) => {
				const lineage = change.split(".");
				// Given this "inline.repeatable.4.text" I want "repeatable".
				// Given this "inline.inline.ddg.text" I want "undefined".
				// Given this "inline.repeatableA.1.inline.repeatableB.3.text" I want "repeatableA" and "repeatableB".
				return lineage.map((it, index) => {
					const field = fieldReferenceLibrary[it];
					const isRepeatable = field?.type === "repeatableGroup";
					if (isRepeatable)
						return change
							.split(".")
							.slice(0, index + 2)
							.join(".");
					return undefined;
				});
			})
			.flat(),
	);

	pathsToMarkAsUpdated.forEach((path) => {
		if (path) {
			const now = new Date().toISOString();
			applyChange(`${path}._date_updated`, now);
			if (user) applyChange(`${path}._updated_by`, user);
		}
	});
}
