import React from "react";

import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { Offline as OfflineIntegration, CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import * as ReactDOMClient from "react-dom/client";
import { Provider } from "react-redux";

import { msalInstance } from "config/msalConfig";

import App from "./App";
import config from "./config";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import store from "./store";
import { actions } from "./store/slices/sw";
import "./i18n";
import { MsalProvider } from "@azure/msal-react";

Sentry.init({
	dsn: "https://b2b0b9cc9f694e999287e4e365c904b9@o141124.ingest.sentry.io/6376071",
	release: config.RELEASE,
	environment: window.location.host,
	autoSessionTracking: true,
	integrations: [
		new CaptureConsole({
			levels: ["error", "warn"],
		}),
		new BrowserTracing(),
		new OfflineIntegration({
			// limit how many events will be localled saved. Defaults to 30.
			maxStoredEvents: 200,
		}),
	],
	// We recommend adjusting this value in production
	sampleRate: 1,
	tracesSampleRate: 1,
	enabled: window.location.hostname !== "localhost",
});

msalInstance.initialize().then(() => {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	const accounts = msalInstance.getAllAccounts();
	if (accounts.length > 0) {
		msalInstance.setActiveAccount(accounts[0]);
	}

	msalInstance.addEventCallback((event: EventMessage) => {
		if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
			const payload = event.payload as AuthenticationResult;
			const account = payload.account;
			msalInstance.setActiveAccount(account);
		}
	});

	const rootEl = document.getElementById("root");
	if (!rootEl) throw new Error("Unable to find root node");
	const root = ReactDOMClient.createRoot(rootEl);

	root.render(
		<Provider store={store}>
			<React.Suspense fallback={<React.Fragment />}>
				<MsalProvider instance={msalInstance}>
					<App />
				</MsalProvider>
			</React.Suspense>
		</Provider>,
	);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
	onSuccess: () => {
		store.dispatch({ type: actions.SW_INIT });
	},
	onUpdate: (reg) => {
		store.dispatch({ type: actions.SW_UPDATE, payload: reg });
	},
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);
