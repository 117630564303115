import React, { useEffect, useState } from "react";

import { IonSkeletonText } from "@ionic/react";
import { useParams } from "react-router-dom";

import { getAssetStatus, getAssetStatusDiff, getAssetsWithLatestStatus } from "api/assetStatus";
import { getForms } from "api/forms";
import { getProjects } from "api/projects";
import { getRecordById } from "api/records";
import { WithApiKeyOrUserAuth } from "components/common/WithApiKeyOrUserAuthHOC";
import CookieProvider from "components/common/CookieProvider";
import Asset from "models/Asset";
import Form from "models/Form";
import FormRecord, { FormValues } from "models/FormRecord";
import Project from "models/Project";
import { ReportRenderer } from "views/ReportView/components/ReportRenderer";
import { Topbar } from "views/ReportView/components/Topbar";

import "./css/styles.css";
import { DeliveryStatusOutput } from "interfaces/AssetStatus";

interface ReportViewState {
	project?: Project;
	asset?: Asset;
	record?: FormRecord;
	siblingRecords: FormRecord[];
	form?: Form;
	statuses?: DeliveryStatusOutput[];
	baseContext?: { [formId: string]: FormValues };
}
export const ReportView: React.FC = () => {
	const { recordId } = useParams<{
		recordId: string;
	}>();

	const searchParams = new URLSearchParams(window.location.search);
	const [compareFrom, compareTo] = [searchParams.get("from") || undefined, searchParams.get("to") || undefined];

	const [localState, setLocalState] = useState<ReportViewState>({
		project: undefined,
		asset: undefined,
		record: undefined,
		siblingRecords: [],
		form: undefined,
		statuses: undefined,
		baseContext: undefined,
	});
	const [changes, setChanges] = useState<string[]>([]);

	const [isCookieSet, setIsCookieSet] = useState(false);

	useEffect(() => {
		let isCancelled = false;
		const initialize = async () => {
			// Proceed record view initialization
			const record = await getRecordById(recordId);
			const project = (await getProjects([record.project_ref])).find((it) => it.ref === record.project_ref);
			const asset = (await getAssetsWithLatestStatus({ assetIds: [record.asset_id] })).items[0];
			const form = (await getForms([record.project_ref])).find((it) => it.id === record.form_id);
			const statuses = await getAssetStatus(record.asset_id);

			if (!form || !record) throw new Error("Record not found");
			const otherFormRecords = await FormRecord.byAssetId(record.asset_id);

			if (isCancelled) return;
			if (!project || !asset) throw new Error("No project or asset.");

			setLocalState({ project, asset, form, record, siblingRecords: otherFormRecords, statuses });
		};
		initialize();
		return () => {
			isCancelled = true;
		};
	}, [recordId]);

	useEffect(() => {
		// Conditionally load changes
		if (!localState.record) return;
		if (compareFrom)
			getAssetStatusDiff(localState.record.asset_id, localState.record.form_id, compareFrom, compareTo).then(
				setChanges,
			);
		else setChanges([]);
	}, [localState.record, compareFrom, compareTo]);

	const skeletonStyle = {
		height: "1.2rem",
		marginLeft: "1.5rem",
		marginTop: "0.9rem",
		marginBottom: "0.9rem",
	};
	const skeletonGroupStyle = {
		height: "3rem",
		marginLeft: "1.5rem",
		paddingTop: "0.5rem",
		paddingBottom: "0.5rem",
	};

	const getComparisonDetails = (statuses: DeliveryStatusOutput[]): { from: string; to?: string } | undefined => {
		const [currStatus, prevStatus] = statuses.slice(0, 2);
		if (currStatus?.status !== "pendingValidation") return;
		return { from: new Date(prevStatus.created_at).toISOString() };
	};

	return (
		<div
			style={{
				display: "flex",
				position: "relative",
				width: "100%",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			{localState.asset?.id && (
				<CookieProvider
					assetId={localState.asset.id}
					onCookieSet={() => {
						setIsCookieSet(true);
					}}
				/>
			)}
			<Topbar
				assetName={localState.asset?.name}
				formName={localState.form?.name}
				dateSynced={localState.record?.date_synced}
				comparison={getComparisonDetails(localState.statuses || [])}
				backUrl={
					localState.project && localState.asset ? `/${localState.project?.ref}/${localState.asset?.id}` : "/projects"
				}
			/>
			{localState.form && localState.project && localState.record && isCookieSet ? (
				<ReportRenderer
					projectRef={localState.project.ref}
					assetId={localState.record.asset_id}
					form={localState.form}
					records={localState.siblingRecords}
					changes={changes}
				/>
			) : (
				<div style={{ width: "100%" }}>
					<IonSkeletonText animated={true} style={{ width: "30%", ...skeletonStyle }} />
					<div style={skeletonGroupStyle}>
						<IonSkeletonText animated={true} style={{ width: "30%", height: "0.6rem" }} />
						<IonSkeletonText animated={true} style={{ width: "45%", height: "0.6rem" }} />
					</div>
					<div style={skeletonGroupStyle}>
						<IonSkeletonText animated={true} style={{ width: "55%", height: "0.6rem" }} />
						<IonSkeletonText animated={true} style={{ width: "70%", height: "0.6rem" }} />
					</div>
					<div style={skeletonGroupStyle}>
						<IonSkeletonText animated={true} style={{ width: "25%", height: "0.6rem" }} />
						<IonSkeletonText animated={true} style={{ width: "40%", height: "0.6rem" }} />
					</div>
					<div style={skeletonGroupStyle}>
						<IonSkeletonText animated={true} style={{ width: "30%", height: "0.6rem" }} />
						<IonSkeletonText animated={true} style={{ width: "45%", height: "0.6rem" }} />
					</div>
					<div style={skeletonGroupStyle}>
						<IonSkeletonText animated={true} style={{ width: "55%", height: "0.6rem" }} />
						<IonSkeletonText animated={true} style={{ width: "70%", height: "0.6rem" }} />
					</div>
					<div style={skeletonGroupStyle}>
						<IonSkeletonText animated={true} style={{ width: "25%", height: "0.6rem" }} />
						<IonSkeletonText animated={true} style={{ width: "40%", height: "0.6rem" }} />
					</div>
					<div style={skeletonGroupStyle}>
						<IonSkeletonText animated={true} style={{ width: "30%", height: "0.6rem" }} />
						<IonSkeletonText animated={true} style={{ width: "45%", height: "0.6rem" }} />
					</div>
					<div style={skeletonGroupStyle}>
						<IonSkeletonText animated={true} style={{ width: "55%", height: "0.6rem" }} />
						<IonSkeletonText animated={true} style={{ width: "70%", height: "0.6rem" }} />
					</div>
					<div style={skeletonGroupStyle}>
						<IonSkeletonText animated={true} style={{ width: "25%", height: "0.6rem" }} />
						<IonSkeletonText animated={true} style={{ width: "40%", height: "0.6rem" }} />
					</div>
					<div style={skeletonGroupStyle}>
						<IonSkeletonText animated={true} style={{ width: "30%", height: "0.6rem" }} />
						<IonSkeletonText animated={true} style={{ width: "45%", height: "0.6rem" }} />
					</div>
					<div style={skeletonGroupStyle}>
						<IonSkeletonText animated={true} style={{ width: "55%", height: "0.6rem" }} />
						<IonSkeletonText animated={true} style={{ width: "70%", height: "0.6rem" }} />
					</div>
					<div style={skeletonGroupStyle}>
						<IonSkeletonText animated={true} style={{ width: "25%", height: "0.6rem" }} />
						<IonSkeletonText animated={true} style={{ width: "40%", height: "0.6rem" }} />
					</div>
				</div>
			)}
		</div>
	);
};

export default WithApiKeyOrUserAuth(ReportView);
