import React from "react";
import { IonItem, IonLabel } from "@ionic/react";

import { Divider } from "components/common/Form/components/Divider";
import { FormField } from "models/Form";
import { WidgetFactory } from "views/ReportView/components/WidgetFactory";
import { FormValues } from "models/FormRecord";

interface ReportRepeatableProps {
	formField: FormField;
	value: (FormValues | null)[]; // TODO: For some unknown reason there may be nulls in the rep. list
	ancestorHistory: string[];
}

export const ReportRepeatable: React.FC<ReportRepeatableProps> = (props: ReportRepeatableProps) => {
	const { value: allItems, formField, ancestorHistory } = props;
	const itemsToRender = allItems
		.filter((it): it is FormValues => !!it) // Remove nulls if any
		.filter((item) => Object.keys(item).length > 2 && !item._is_deleted);

	if (!itemsToRender.length) {
		return <React.Fragment />;
	}

	return (
		<React.Fragment>
			<IonItem lines="none">
				<IonLabel
					className="ion-text-wrap"
					style={{
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', Roboto, sans-serif",
						fontSize: "0.875rem",
						lineHeight: "1rem",
						fontWeight: "bold",
					}}
				>
					{formField.label}
				</IonLabel>
			</IonItem>
			{formField.children && formField.children.length > 0 && (
				<div style={{ borderLeft: "0.25rem solid var(--ion-color-gray200)", padding: "0.5rem", paddingLeft: "1rem" }}>
					{itemsToRender.map((item, index) => (
						<React.Fragment key={index + 1}>
							<Divider color="var(--ion-color-gray200)">
								<div
									style={{
										position: "absolute",
										fontSize: "0.75rem",
										fontWeight: "bold",
										top: "-0.5rem",
										left: "0.25rem",
										zIndex: 5,
										borderRadius: "50%",
										color: "var(--ion-color-gray500)",
										backgroundColor: "var(--ion-background-color)",
										textAlign: "center",
										paddingInline: ".25rem",
									}}
								>
									{`${formField.label} #${index + 1}`}
								</div>
							</Divider>
							<div style={{ marginBottom: "1rem" }}>
								{(formField.children ?? []).map((field, idx) => {
									const value = item[field.name];
									if (!value) return <React.Fragment key={idx} />;
									return (
										<WidgetFactory
											key={field.name}
											formField={field}
											dataToRender={item}
											ancestorHistory={[
												...ancestorHistory,
												formField.name,
												`${allItems.findIndex((it) => it && it.id === item.id)}`,
											]}
										/>
									);
								})}
							</div>
						</React.Fragment>
					))}
					<Divider color="var(--ion-color-gray200)" />
				</div>
			)}
		</React.Fragment>
	);
};
