import React, { useEffect, useState } from "react";

import { IonContent, IonItem, IonPage, IonSpinner } from "@ionic/react";
import { useParams, withRouter } from "react-router-dom";

import { getAssetsWithLatestStatus } from "api/assetStatus";
import { getForms } from "api/forms";
import { getProjects } from "api/projects";
import { getRecords, putRecords } from "api/records";
import useToken from "components/common/Auth/hooks/useToken";
import CookieProvider from "components/common/CookieProvider";
import FormComponent from "components/common/Form";
import { EmbeddedTopbar } from "components/common/Form/components/Topbar/EmbeddedTopbar";
import { buildExternalContext } from "components/common/Form/utils/buildExternalContext";
import { checkIfFrozen } from "components/common/Form/utils/checkIfFrozen";
import Asset from "models/Asset";
import Form from "models/Form";
import FormRecord, { FormValues } from "models/FormRecord";
import Project from "models/Project";
import { useAppDispatch } from "store";
import { actions as formActions } from "store/slices/form";
import { actions as historyActions } from "store/slices/history";
import syncFunction from "utils/sync";
import { SubmitFormFn } from "views/RecordView/RecordView";
import { DigestedFieldReferenceLibrary } from "@arup-group/dhub-forms-engine";
import DigestedFieldLibrary from "models/DigestedFieldLibrary";

interface State {
	project: Project;
	asset: Asset;
	record: FormRecord;
	siblingRecords: FormRecord[];
	form: Form;
}
const EmbeddedView: React.FC = () => {
	const dispatch = useAppDispatch();
	const token = useToken();
	const { projectRef, assetId, recordId } = useParams<{
		projectRef: string;
		assetId: string;
		recordId: string;
	}>();

	const [localState, setLocalState] = useState<State>();

	useEffect(() => {
		let isCancelled = false;
		const initialize = async () => {
			if (token) {
				await syncFunction(token, [], [], [], projectRef, assetId);
			} else {
				console.error("EmbeddedView - No token found");
				return;
			}
			// Proceed record view initialization
			const projectQuery = await getProjects([projectRef]);
			await Project.setMany(projectQuery);
			const project = await Project.get(projectRef);
			const assetsQuery = await getAssetsWithLatestStatus({ assetIds: [assetId] });
			await Asset.setMany(assetsQuery.items);
			const asset = await Asset.get(assetId);
			const recordsQuery = await getRecords([projectRef], [assetId]);
			await FormRecord.setMany(recordsQuery);
			const record = await FormRecord.get(recordId);
			let dfl: DigestedFieldReferenceLibrary | undefined;
			let hash: string | undefined;
			try {
				const dflDb = await DigestedFieldLibrary.get(record.id);
				dfl = dflDb.dfl;
				hash = dflDb.hash;
			} catch (_) {
				console.log("No local dfl. Initializating engine");
			}
			const siblingRecords = await FormRecord.byAssetId(assetId);
			const formsQuery = await getForms([projectRef]);
			await Form.setMany(formsQuery);
			const form = await Form.get(record.form_id);

			const canEdit = await Project.hasFeature(projectRef, "can-edit");

			const externalContext = await buildExternalContext(form, assetId);
			if (isCancelled) return;
			dispatch(
				formActions.setActive({
					active: { ...form },
					dfl: dfl && hash ? { value: dfl, hash } : undefined,
					projectRef: project.ref,
					assetId: asset.id,
					recordId: record.id,
					externalContext,
					deliveryStatus: record.delivery_status,
					isFrozen: checkIfFrozen(record.delivery_status, canEdit),
				}),
			);
			setLocalState({ project, asset, form, record, siblingRecords });
		};
		initialize().catch((err) => console.error(err));
		return () => {
			isCancelled = true;
		};
	}, [token, projectRef, assetId, recordId, dispatch]);

	const onSubmit: SubmitFormFn = async (
		data: FormValues,
		changes: string[],
		history: string[],
		options = {
			exit: false,
			stay: false,
			completed: false,
		},
		popHistory = true,
	) => {
		const { completed } = options;
		if (!localState?.form) {
			throw Error("Form not available at the time of submitting");
		}
		const record = await FormRecord.get(recordId);
		if (!record) {
			throw Error("Record not available at the time of submitting");
		}
		const allChanges = [...new Set([...record.changes, ...changes])];
		if (!allChanges.length) {
			console.log("No changes detected, skipping");
			return;
		}
		const newRecord = new FormRecord({
			...record,
			data,
			changes: allChanges,
			completed: completed,
			date_synced: new Date(),
			date_updated: new Date(),
		});
		setLocalState({ ...localState, record: newRecord });
		await newRecord.save();
		if (popHistory !== false) {
			dispatch(historyActions.popHistory());
		}
		window.parent.postMessage("forms-put-records-pending", "*");
		await putRecords([newRecord]);
		window.parent.postMessage("forms-put-records-finished", "*");
	};

	useEffect(() => {
		// send a message to the parent to notify this view is ready
		window.parent.postMessage({ id: "formsEmbeddedViewReady" }, "*");
	}, []);

	return (
		<IonPage>
			<IonContent forceOverscroll={false} style={{ backgroundColor: "green" }}>
				{localState ? (
					<FormComponent
						form={localState.form}
						record={localState.record}
						siblingRecords={localState.siblingRecords}
						onSubmit={onSubmit}
						exitForm={() => {
							return;
						}}
						onPrint={() => {
							return;
						}}
						TopbarComponent={EmbeddedTopbar}
					/>
				) : (
					<>
						<IonItem lines="none">
							<IonSpinner name="lines-sharp-small" style={{ margin: "auto" }} />
						</IonItem>
					</>
				)}
			</IonContent>
			<CookieProvider assetId={assetId} />
		</IonPage>
	);
};

export default withRouter(EmbeddedView);
