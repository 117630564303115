import { IRecordSummary } from "api/records";
import FormRecord from "models/FormRecord";
import Asset from "../models/Asset";
import Form, { FormField } from "../models/Form";
import Project from "../models/Project";

const fields: FormField[] = [
	{
		name: "carpeta1",
		type: "drillDownGroup",
		label: "Field Gallery",
		required: true,
		children: [
			{
				name: "carpeta2",
				type: "inlineGroup",
				label: "Inline Group",
				required: true,
				children: [
					{
						name: "carpeta3",
						type: "inlineGroup",
						label: "Field Gallery (Nested Inline Group)",
						required: true,
						children: [
							{
								// To check that the text does not disappear when clicking on dropdown
								name: "debug-text-syle",
								label: "Text to check style",
								type: "text",
							},
							{
								name: "debug-dropdown-inline",
								label: "Dropdown demo",
								type: "dropdown",
								choices: [
									{ value: "a", label: "A" },
									{ value: "b", label: "B" },
								],
							},
							{
								name: "debugRadio-inline",
								label: "Radio demo",
								type: "radio",
								choices: [
									{ value: "a", label: "A" },
									{ value: "b", label: "B" },
								],
							},
							{
								name: "debug-textarea-inline",
								label: "Text area demo",
								type: "textarea",
							},
							{
								name: "debug-location-inline",
								label: "Location demo",
								type: "location",
							},
							{
								name: "debug-images-inline",
								label: "Images demo",
								type: "images",
							},
							{
								name: "debug-sketch-inline",
								label: "Sketch demo",
								type: "sketch",
							},
							{
								name: "debug-files-inline",
								label: "Files demo",
								type: "files",
							},
							{
								name: "debug-disclaimer-inline",
								label:
									"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Natoque penatibus et magnis dis. Risus nullam eget felis eget. Pellentesque pulvinar pellentesque habitant morbi tristique senectus et. Cursus in hac habitasse platea dictumst quisque. Pellentesque adipiscing commodo elit at. Vitae tortor condimentum lacinia quis vel eros donec ac odio. Risus commodo viverra maecenas accumsan lacus vel facilisis volutpat. Odio tempor orci dapibus ultrices in iaculis nunc. Posuere sollicitudin aliquam ultrices sagittis orci. Suscipit adipiscing bibendum est ultricies integer quis.",
								type: "disclaimer",
							},
							{
								name: "debug-link-inline",
								label: "https://www.google.com",
								shortLabel: "This is a link (B)",
								type: "link",
							},
						],
					},
				],
			},
			{
				name: "debug-text",
				label: "Text demo",
				type: "text",
			},
			{
				name: "debugNumber",
				label: "Number between 0 and 10",
				type: "number",
				hardRequired: true,
				min: 0,
				max: 10,
			},
			{
				name: "debugDropdown",
				label: "Dropdown demo",
				type: "dropdown",
				choices: [
					{ value: "a", label: "A" },
					{ value: "b", label: "B" },
				],
			},
			{
				name: "debugDropdownMultiple",
				label: "Multi dropdown demo",
				type: "dropdown",
				multiple: true,
				choices: [
					{ value: "a", label: "A" },
					{ value: "b", label: "B" },
				],
			},
			{
				name: "debugRadio",
				label: "Radio demo",
				type: "radio",
				choices: [
					{ value: "a", label: "A" },
					{ value: "b", label: "B" },
				],
			},
			{
				name: "debugRadioMultiple",
				label: "MultiRadio demo",
				type: "radio",
				multiple: true,
				choices: [
					{ value: "a", label: "A" },
					{ value: "b", label: "B" },
				],
			},
			{
				name: "debug-textarea",
				label: "Text area demo",
				type: "textarea",
			},
			{
				name: "debug-location",
				label: "Location demo",
				type: "location",
			},
			{
				name: "debug-drawings",
				label: "Drawings demo",
				type: "drawings",
				validate: "$ !choice.assetId || assetId == choice.assetId",
				choices: [
					{ value: "drawing1", label: "Drawing 1", filepath: "bugs.jpeg" },
					{ value: "drawing2", label: "Drawing 2", filepath: "57.jpeg" },
					{
						value: "drawing3",
						label: "Drawing only for asset 1",
						filepath: "bugs.jpeg",
						assetId: "debug-1",
					},
					{
						value: "drawing4",
						label: "Drawing only for asset 2",
						filepath: "57.jpeg",
						assetId: "debug-2",
					},
				],
			},
			{
				name: "debug-images",
				label: "Images demo",
				type: "images",
			},
			{
				name: "debug-sketch",
				label: "Sketch demo",
				type: "sketch",
			},
			{
				name: "debug-files",
				label: "Files demo",
				type: "files",
			},
			{
				name: "debug-disclaimer-inline",
				label:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
				type: "disclaimer",
			},
			{
				name: "debug-link-inline",
				label: "https://www.google.com",
				shortLabel: "This is a link (A)",
				type: "link",
			},
			{
				name: "debugCalculationInput",
				label: "Calculation input",
				type: "text",
			},
			{
				name: "debugCalculationResult",
				label: "Calculation result",
				type: "calculation",
				alert: '$debugCalculationInput ? "" : "No input value!"',
				alertColor: "primary",
				calculation: "$ formatCalculation(10 / debugCalculationInput)",
				dependsOn: ["debugCalculationInput"],
			},
			{
				name: "debugCalculationResult2",
				label: "Calculation result 2",
				type: "calculation",
				alert: '$debugCalculationInput ? "" : "No input value!"',
				alertColor: "primary",
				calculation: "$ 5 + debugCalculationInput || 0 + debugCalculationResult || 0",
				dependsOn: ["debugCalculationInput", "debugCalculationResult"],
			},
			{
				name: "debugShowDifferentTextDropdown",
				label: "Show text box A or B",
				type: "radio",
				choices: [
					{ label: "A", value: "a" },
					{ label: "B", value: "b" },
				],
			},
			{
				name: "debugRelevantDependsOn1",
				label: "This is text box A",
				type: "text",
				dependsOn: ["debugShowDifferentTextDropdown"],
				relevant: '$ includes(debugShowDifferentTextDropdown,"a")',
			},
			{
				name: "debugRelevantDependsOn2",
				label: "This is text box B",
				type: "text",
				dependsOn: ["debugShowDifferentTextDropdown"],
				relevant: '$ includes(debugShowDifferentTextDropdown,"b")',
			},
			{
				name: "debugAlert",
				label: "Alert demo",
				type: "text",
				alert: "$debugAlert",
				alertColor: "danger",
				alertIcon: "alertCircleOutline",
			},
		],
	},
	{
		name: "dynamic",
		type: "drillDownGroup",
		label: "Dynamic features",
		required: true,
		children: [
			{
				name: "featureSelector",
				label: "Selección de funcionalidad",
				type: "dropdown",
				choices: [
					{ label: "Relevant (makes below field non-relevant)", value: "relevant" },
					{ label: "Required (makes below field required)", value: "required" },
					{ label: "Hard Required (makes below field hard-required", value: "hardRequired" },
					{ label: "Disabled (makes below field disabled", value: "disabled" },
				],
			},
			{
				name: "featureDisplay",
				label: "Feature Display",
				type: "text",
				defaultValue: "Default value",
				relevant: '$featureSelector !== "relevant"',
				required: '$featureSelector === "required"',
				hardRequired: '$featureSelector === "hardRequired"',
				disabled: '$featureSelector === "disabled"',
				dependsOn: ["featureSelector"],
			},
			{
				name: "calculationDisplay",
				label: "Calculation Display (reproduces endpoint of selector above)",
				type: "calculation",
				calculation: "$featureSelector",
				dependsOn: ["featureSelector"],
			},
		],
	},
	{
		name: "drilldown1",
		type: "drillDownGroup",
		label: "Dynamic choice selection",
		required: true,
		children: [
			{
				name: "sortChoices",
				type: "dropdown",
				label: "Sort choices",
			},
			{
				type: "dropdown",
				multiple: true,
				name: "multiselect",
				label: "Inner Multi Select",
				defaultValue: ["a", "d"],
				choices: [
					{ label: "A", value: "a" },
					{ label: "B", value: "b" },
					{ label: "C", value: "c" },
					{ label: "D", value: "d" },
				],
			},
			{
				type: "dropdown",
				name: "select",
				label: "Select one of above (dependency with name only)",
				choices: [
					{ label: "A", value: "a" },
					{ label: "B", value: "b" },
					{ label: "C", value: "c" },
					{ label: "D", value: "d" },
				],
				validate: "$choice.value in multiselect",
				dependsOn: ["multiselect"],
			},
			{
				type: "dropdown",
				name: "selectReverse",
				label: "Select one of above (dependency with name only) - Reverse sort",
				choices: [
					{ label: "A", value: "a" },
					{ label: "B", value: "b" },
					{ label: "C", value: "c" },
					{ label: "D", value: "d" },
				],
				sortChoices: "descending",
				validate: "$choice.value in multiselect",
				dependsOn: ["multiselect"],
			},
		],
	},
	{
		name: "complex1/1",
		type: "drillDownGroup",
		label: "Complex Drilldown 1",
		required: true,
		iconConfig: {
			label: "Drilldown test",
			icon: '$iconForGroups || "americanFootball"',
			color: '$colorForGroups || "yellow"',
		},
		dependsOn: ["iconForGroups", "colorForGroups"],
		children: [
			{
				name: "complex2/1",
				type: "inlineGroup",
				label: "2.1",
				required: true,
				iconConfig: {
					label: "Drilldown test",
					icon: '$iconForGroups || "americanFootball"',
					color: '$colorForGroups || "orange"',
				},
				dependsOn: ["iconForGroups", "colorForGroups"],
				children: [
					{
						name: "complex3/1/0",
						type: "text",
						label: "3.1.0",
					},
					{
						name: "complex3/1/1",
						type: "drillDownGroup",
						label: "3.1.1",
						required: true,
						children: [
							{
								name: "complex4/1/1",
								type: "inlineGroup",
								label: "4.1.1",
								required: true,
								children: [
									{
										type: "text",
										name: "drilldownObservacion4/1/1",
										label: "Observaciones",
									},
								],
							},
							{
								name: "complex4/1/2",
								type: "inlineGroup",
								label: "4.1.2",
								required: true,
								children: [
									{
										type: "text",
										name: "drilldownObservacion4/1/2",
										label: "Observaciones",
									},
								],
							},
						],
					},
					{
						name: "iconForGroups",
						label: "Icono para la demo de item config",
						type: "dropdown",
						choices: [
							{ label: "Futbol americano", value: "americanFootball" },
							{ label: "Bolso", value: "bagHandle" },
							{ label: "Globo", value: "balloon" },
							{ label: "Pesas", value: "barbell" },
						],
						defaultValue: "americanFootball",
					},
					{
						name: "colorForGroups",
						label: "Color para la demo de item config",
						type: "dropdown",
						choices: [
							{ label: "Red", value: "red" },
							{ label: "Yellow", value: "yellow" },
							{ label: "Green", value: "green" },
							{ label: "Blue", value: "blue" },
							{ label: "Orange", value: "orange" },
						],
						defaultValue: "orange",
					},
				],
			},
			{
				name: "complex2/2",
				type: "drillDownGroup",
				label: "2.2",
				required: true,
				children: [
					{
						name: "complex3/2/1",
						type: "drillDownGroup",
						label: "3.2.1",
						required: true,
						children: [
							{
								type: "text",
								name: "drilldownObservacion3/2/1",
								label: "Observaciones",
							},
						],
					},
					{
						name: "complex3/2/2",
						type: "drillDownGroup",
						label: "3.2.2",
						required: true,
						children: [
							{
								type: "text",
								name: "drilldownObservacion3/2/2",
								label: "Observaciones",
							},
						],
					},
					{
						name: "complex3/2/3",
						type: "drillDownGroup",
						label: "3.2.3",
						required: true,
						children: [
							{
								type: "text",
								name: "drilldownObservacion3/2/3",
								label: "Observaciones",
							},
						],
					},
				],
			},
			{
				name: "complex2/3",
				type: "drillDownGroup",
				label: "2.3",
				required: true,
				children: [
					{
						name: "complex3/3/1",
						type: "inlineGroup",
						label: "3.3.1",
						required: true,
						children: [
							{
								type: "text",
								name: "drilldownObservacion3/3/1",
								label: "Observaciones",
							},
						],
					},
					{
						name: "complex3/3/2",
						type: "drillDownGroup",
						label: "3.3.2",
						required: true,
						children: [
							{
								type: "text",
								name: "drilldownObservacion3/3/2",
								label: "Observaciones",
							},
						],
					},
					{
						name: "complex3/3/3",
						type: "drillDownGroup",
						label: "3.3.3",
						required: true,
						children: [
							{
								type: "text",
								name: "drilldownObservacion3/3/3",
								label: "Observaciones",
							},
						],
					},
				],
			},
		],
	},
	{
		name: "ddg",
		type: "drillDownGroup",
		label: "Repetible",
		required: true,
		children: [
			{
				type: "radio",
				multiple: true,
				name: "colors",
				label: "Outer Multi Select for Color",
				defaultValue: ["red", "yellow", "green", "blue"],
				disabled: false,
				choices: [
					{ label: "Red", value: "red" },
					{ label: "Yellow", value: "yellow" },
					{ label: "Green", value: "green" },
					{ label: "Blue", value: "blue" },
				],
			},
			{
				type: "radio",
				multiple: true,
				name: "icons",
				label: "Outer icon selection",
				choices: [
					{ label: "Futbol americano", value: "americanFootball" },
					{ label: "Bolso", value: "bagHandle" },
					{ label: "Globo", value: "balloon" },
					{ label: "Pesas", value: "barbell" },
				],
				defaultValue: ["americanFootball", "bagHandle", "balloon", "barbell"],
			},
			{
				name: "repeatable",
				type: "repeatableGroup",
				label: "Repetible",
				required: true,
				itemTitle: "$nombre",
				itemSubtitle: "$(colorSelect ? colorSelect : '') + ' ' + (iconSelect ? iconSelect : '')",
				iconConfig: {
					color: "$colors[colorSelect] || colorSelect",
					icon: "$iconSelect",
					label: "Summary",
				},
				baseContext: {
					colors: { red: "tomato", yellow: "yellow", green: "darkgreen" },
				},
				// sortBy: "title",
				// displayRefNo: false,
				dependsOn: ["nombre", "colorSelect", "iconSelect"],
				children: [
					{
						type: "text",
						name: "nombre",
						label: "Nombre",
						filter: "exact",
						required: true,
						defaultValue: "Test Value",
					},
					{
						type: "calculation",
						name: "dateCreated",
						label: "Fecha Creación Auto",
						calculation: "$_date_created",
						dependsOn: ["_date_created"],
					},
					{
						type: "dropdown",
						name: "colorSelect",
						label: "Select a color",
						choices: [
							{ label: "Red", value: "red" },
							{ label: "Yellow", value: "yellow" },
							{ label: "Green", value: "green" },
							{ label: "Blue", value: "blue" },
						],
						validate: "$choice.value in colors",
						sortChoices: "ascending",
						dependsOn: ["colors"],
						filter: "exact",
					},
					{
						type: "dropdown",
						name: "colorMultipleSelect",
						label: "Select a multiple color",
						multiple: true,
						choices: [
							{ label: "Red", value: "red" },
							{ label: "Yellow", value: "yellow" },
							{ label: "Green", value: "green" },
							{ label: "Blue", value: "blue" },
						],
						validate: "$choice.value in colors",
						sortChoices: "ascending",
						dependsOn: ["colors"],
						filter: "exact",
					},
					{
						type: "dropdown",
						name: "iconSelect",
						label: "Select an icon",
						choices: [
							{ label: "Futbol americano", value: "americanFootball", color: "red" },
							{ label: "Bolso", value: "bagHandle", color: "red" },
							{ label: "Globo", value: "balloon", color: "yellow" },
							{ label: "Pesas", value: "barbell", color: "yellow" },
						],
						defaultValue: "americanFootball",
						validate: "$choice.value in icons && choice.color===colorSelect",
						dependsOn: ["icons", "colorSelect"],
					},
					{
						type: "drillDownGroup",
						name: "drillInRepeatable",
						label: "Drill in repeatable",
						children: [
							{
								type: "text",
								name: "textInDrill",
								label: "Text In drill",
								filter: "exact",
							},
						],
					},
				],
			},
		],
	},
	{
		name: "crossFormTesting",
		label: "Cross Form Dependencies",
		type: "drillDownGroup",
		children: [
			{
				name: "crossFormText1",
				label: "This field is read for the next field calculation",
				type: "text",
			},
			{
				name: "crossFormText2",
				label: "This field is auto calculated from the previous field vale of form 1",
				type: "calculation",
				calculation: "$external.debugForm1.crossFormText1",
				disabled: true,
				alert: '$debugForm1.crossFormText1 ? "" : "No value found!"',
				alertColor: "danger",
			},
		],
	},
	{
		name: "repeatableAdditionalData",
		label: "Nested repeatables",
		type: "drillDownGroup",
		children: [
			{
				name: "repeatableAdditionalDataA",
				label: "Depth of one",
				type: "repeatableGroup",
				children: [
					{
						type: "text",
						name: "repeatableAdditionalDataANombre",
						label: "Nombre A",
						filter: "includes",
					},
				],
			},
			{
				name: "repeatableAdditionalDataB",
				label: "Depth of two",
				type: "repeatableGroup",
				children: [
					{
						type: "text",
						name: "repeatableAdditionalDataBNombre",
						label: "Nombre B",
						filter: "includes",
					},
					{
						name: "repeatableAdditionalDataC",
						label: "End",
						type: "repeatableGroup",
						children: [
							{
								type: "text",
								name: "repeatableAdditionalDataCNombre",
								label: "Nombre C",
								filter: "includes",
							},
						],
					},
				],
			},
		],
	},
	{
		name: "context",
		type: "drillDownGroup",
		label: "Context",
		children: [
			{
				name: "assetIdFromBaseContext",
				label: "assetId from base context",
				type: "calculation",
				dependsOn: [],
				calculation: "$ assetId",
			},
			{
				name: "projectRefFromBaseContext",
				label: "projectRef from base context",
				type: "calculation",
				dependsOn: [],
				calculation: "$ projectRef",
			},
		],
	},
	{
		name: "completition",
		type: "drillDownGroup",
		label: "Completition",
		children: [
			{
				name: "compl-toggle",
				label: "Required field (top level)",
				type: "text",
				required: true,
			},
			{
				name: "compl-container-1",
				label: "Inline Group",
				type: "inlineGroup",
				children: [
					{
						name: "compl-toggle-1-1",
						label: "Required field (top level)",
						type: "text",
						required: true,
					},
					{
						name: "compl-container-1-1",
						label: "Inline Group",
						type: "inlineGroup",
						children: [
							{
								name: "compl-toggle-1-1-1",
								label: "Required field (top level)",
								type: "text",
								required: true,
							},
						],
					},
					{
						name: "compl-repeatable-1-2",
						label: "Repetable Group",
						type: "repeatableGroup",
						children: [
							{
								name: "compl-toggle-1-2-1",
								label: "Required field (top level)",
								type: "text",
								required: true,
							},
						],
					},
				],
			},
			{
				name: "compl-container-2",
				label: "Inline Group 2",
				type: "inlineGroup",
				children: [
					{
						name: "compl-toggle-2-1",
						label: "Required field (top level)",
						type: "text",
						required: true,
					},
				],
			},
		],
	},
];

const form1 = new Form({
	id: "debugForm1",
	name: "Formulario Prueba",
	type: "default",
	fields,
	sorted_by_field: "carpeta1.carpeta2.carpeta3.carpetaObservacion",
	secondary_title_field: "carpeta1.carpeta2.carpeta3.carpetaObservacion",
});
const form2 = new Form({
	id: "debugForm2",
	name: "Formulario Prueba 2",
	type: "default",
	fields,
	sorted_by_field: "carpeta1.carpeta2.carpeta3.carpetaObservacion",
	secondary_title_field: "carpeta1.carpeta2.carpeta3.carpetaObservacion",
	externalDependencies: [{ formId: "debugForm1", dependencies: { crossFormText1: "crossFormTesting.crossFormText1" } }],
});

const project = new Project({
	ref: "debug",
	name: "Proyecto Debug",
	language: "es",
	configuration: {
		id: "debug",
		features: [],
		project_ref: "debug",
		default_form_id: "form-1",
		form_ids: ["debugForm1", "debugForm2"],
		configuration_filter: [],
	},
	created_at: new Date(),
});
const asset1 = new Asset({
	id: "debug-1",
	project_ref: "debug",
	name: "Activo Prueba 1",
	location: "loc1",
	created_at: new Date(),
	is_deleted: false,
});
const asset2 = new Asset({
	id: "debug-2",
	project_ref: "debug",
	name: "Activo Prueba 2",
	location: "loc2",
	created_at: new Date(),
	is_deleted: false,
});

const initDebugProject = () => {
	project.save();
	asset1.save();
	asset2.save();
	form1.save();
	form2.save();

	for (const asset of [asset1, asset2]) {
		for (const form of [form1, form2]) {
			const record = new FormRecord({
				id: `${asset.id}.${form.id}`,
				name: form.name,
				form_id: form.id,
				asset_id: asset.id,
				project_ref: project.ref,
				data: {},
				changes: [],
				delivery_status: "inProgress",
				completed: undefined,
			});
			record.save();
		}
	}
};

export const DEBUG_PROJECTS: string[] = ["debug"];
export const DEBUG_ASSETS: string[] = ["debug-1", "debug-2"];
export const DEBUG_FORMS: string[] = ["debugForm1", "debugForm2"];
export const DEBUG_RECORD_SUMMARIES: IRecordSummary[] = [
	{
		id: "debug-1.debugForm1",
		project_ref: "debug",
		asset_id: "debug-1",
		form_id: "debugForm1",
		is_deleted: false,
		delivery_status: "inProgress",
		date_synced: "",
	},
	{
		id: "debug-1.debugForm2",
		project_ref: "debug",
		asset_id: "debug-1",
		form_id: "debugForm2",
		is_deleted: false,
		delivery_status: "inProgress",
		date_synced: "",
	},
	{
		id: "debug-2.debugForm1",
		project_ref: "debug",
		asset_id: "debug-2",
		form_id: "debugForm1",
		is_deleted: false,
		delivery_status: "inProgress",
		date_synced: "",
	},
	{
		id: "debug-2.debugForm2",
		project_ref: "debug",
		asset_id: "debug-2",
		form_id: "debugForm2",
		is_deleted: false,
		delivery_status: "inProgress",
		date_synced: "",
	},
];

export default initDebugProject;
