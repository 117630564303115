import config from "config";

export const handleCfRedirect = () => {
	const cfRedirect = localStorage.getItem("cf-redirect");
	if (cfRedirect) {
		localStorage.removeItem("cf-redirect");
		// Avoid redirection to any other origin
		const url = new URL(cfRedirect);
		if (url.hostname === "dhub.arup.com" || url.hostname.endsWith(".dhub.arup.com")) {
			window.location.href = config.DHUB + `${url.pathname}${url.search}`;
		}
	}
};
