import { Configuration, PopupRequest, PublicClientApplication } from "@azure/msal-browser";

import config from ".";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
	auth: {
		clientId: config.AZURE_AD_CLIENT_ID,
		authority: `https://login.microsoftonline.com/${config.AZURE_AD_TENANT_ID}`,
		redirectUri: "/",
		postLogoutRedirectUri: "/",
	},
	system: {
		allowNativeBroker: false, // Disables WAM Broker
	},
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
	scopes: ["User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
	// graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const msalInstance = new PublicClientApplication(msalConfig);
