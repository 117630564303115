import React from "react";

import { IonLabel, IonList, IonListHeader } from "@ionic/react";
import { useTranslation } from "react-i18next";

import { FieldType, FormField } from "models/Form";
import NavigationBreadcrumbs from "components/common/Form/components/FormPage/NavigationBreadcrumbs";
import { useAppSelector } from "store";

interface IProps {
	field?: FormField;
	siblingTypes?: FieldType[];
}
type PropsType = IProps;

const FormPageHeader: React.FC<PropsType> = (props) => {
	const { field, siblingTypes } = props;
	const { t, i18n } = useTranslation();
	const active = useAppSelector((state) => state.history.active);
	const formName = useAppSelector((state) => state.form.active?.name);

	const hasComplexChildren = siblingTypes?.includes("drillDownGroup") || siblingTypes?.includes("inlineGroup");

	return (
		<>
			<IonList>
				<NavigationBreadcrumbs />
				<IonListHeader
					lines="none"
					style={{
						"--inner-padding-start": "0rem",
						"--inner-padding-end": "0rem",
						borderBottom: "1px solid #718096",
					}}
				>
					<IonLabel className="ion-text-wrap" style={{ fontWeight: "bold", maxWidth: "calc(50% - 2rem)" }}>
						{field ? field.label : formName}
						{field && field.type === "repeatableGroup" ? `(#${Number(active) + 1})` : undefined}
					</IonLabel>
					{hasComplexChildren ? (
						<IonLabel style={{ color: "var(--ion-color-medium-tint)", textAlign: "end", maxWidth: "calc(50% - 2rem)" }}>
							{i18n.format(t("completitionStatus"), "capitalize")}
						</IonLabel>
					) : (
						<React.Fragment />
					)}
				</IonListHeader>
			</IonList>
		</>
	);
};

export default FormPageHeader;
