import { IonButton, IonIcon, isPlatform } from "@ionic/react";
import { minimize } from "assets/icons";
import { IDrawingRef } from "interfaces/IDrawingRef";
import OpenSeaDragon, { OverlayRotationMode, Placement } from "openseadragon";
import React, { useEffect, useState } from "react";
import { buildMapPin } from "./utils/buildMapPin";
import { DigestedRefField } from "@arup-group/dhub-forms-engine";

interface IProps {
	image?: string;
	currValue?: IDrawingRef;
	onChange: (value: string) => void;
	onBlur: () => void;
	digestedField: DigestedRefField;
}

type PropsType = IProps;
const OpenSeaDragonViewer: React.FC<PropsType> = (props) => {
	const { image, currValue, onChange, onBlur, digestedField } = props;
	const [viewer, setViewer] = useState<OpenSeaDragon.Viewer | null>(null);
	const [point, setPoint] = useState<[number, number] | undefined>(
		currValue?.geojson?.geometry?.coordinates ?? undefined,
	);

	const element = buildMapPin("point1", "24px");

	useEffect(() => {
		viewer && viewer.destroy();
		const openSeaDragonDiv = document.getElementById("openSeaDragonId"); // Get the div element by ID
		if (openSeaDragonDiv) openSeaDragonDiv.innerHTML = ""; // Delete all the content inside the div
	}, []);

	useEffect(() => {
		if (image !== undefined) {
			InitOpenSeadragon();
		}
	}, [image]);

	useEffect(() => {
		viewer && viewer.removeOverlay("point1");
		if (viewer && point) {
			viewer.addOverlay({
				element: element,
				rotationMode: OverlayRotationMode.NO_ROTATION,
				placement: Placement.BOTTOM,
				location: new OpenSeaDragon.Point(point[0], point[1]),
			});
		}
	}, [viewer]);

	const InitOpenSeadragon = async () => {
		viewer && viewer.destroy();
		const map = OpenSeaDragon({
			id: "openSeaDragonId",
			tileSources: {
				type: "image",
				url: window.location.hostname === "localhost" ? `${origin}/placeholder.png` : `${origin}/drawings/${image}`,
				ajaxWithCredentials: false,
			},
			gestureSettingsMouse: { clickToZoom: false, dblClickToZoom: false },
			gestureSettingsTouch: { clickToZoom: false, dblClickToZoom: false },
			degrees: 0,
			showRotationControl: false,
			showNavigationControl: false,
			maxZoomPixelRatio: 32,
			immediateRender: false,
			iOSDevice: true,
			useCanvas: isPlatform("ios") ? false : true,
			visibilityRatio: 1,
		});

		if (!digestedField.disabled) {
			map.addHandler("canvas-click", function (event) {
				// calculate the new x, y coordinates
				const viewportPoint = map.viewport.pointFromPixel(event.position);
				const maxY = map.world.getHomeBounds().height;
				if (
					viewportPoint.x < 1 &&
					viewportPoint.x > 0 &&
					viewportPoint.y < maxY &&
					viewportPoint.y > 0 &&
					event.quick
				) {
					map.removeOverlay("point1");
					map.addOverlay({
						element: element,
						rotationMode: OverlayRotationMode.NO_ROTATION,
						placement: Placement.BOTTOM,
						location: viewportPoint,
					});
					setPoint([viewportPoint.x, viewportPoint.y]);
				}
			});
		}
		setViewer(map);
	};

	useEffect(() => {
		onBlur();
		onChange(
			JSON.stringify({
				...currValue,
				geojson: { type: "Feature", geometry: { type: "Point", coordinates: point } },
			} as IDrawingRef),
		);
	}, [point]);

	return (
		<React.Fragment>
			<div
				id="openSeaDragonId"
				style={{
					height: "100%",
					width: "100%",
					zIndex: "0",
				}}
			/>
			<IonButton
				id="iconButton"
				fill="clear"
				style={{
					position: "absolute",
					bottom: "4rem",
					right: "0rem",
					width: "3rem",
					height: "3rem",
					backgroundColor: "white",
					color: "white",
					transform: "translate(-50%, -50%)",
					zIndex: "200",
					display: "block",
				}}
				onClick={() => {
					viewer && viewer.viewport.goHome();
				}}
			>
				<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<IonIcon
						src={minimize}
						style={{
							strokeWidth: "1.2px",
							fontSize: "1.75rem",
							color: "#718096",
						}}
					/>
				</div>
			</IonButton>
		</React.Fragment>
	);
};
export default OpenSeaDragonViewer;
