import React, { useRef } from "react";

import { IonButton, IonButtons, IonContent, IonIcon, IonModal, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { close } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import { FormEngine } from "@arup-group/dhub-forms-engine";
import Form from "models/Form";
import FormRecord, { FormValues, IDataValue } from "models/FormRecord";

import { useAppDispatch, useAppSelector } from "../../../store";
import { actions } from "../../../store/slices/filters";
import Filters from "./Filters";

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	formEngine: FormEngine;
	record: FormRecord;
	form: Form;
	siblingRecords: FormRecord[];
}
const FiltersModal: React.FC<IProps> = (props) => {
	const { isOpen, onClose, formEngine, record, form, siblingRecords } = props;
	const { t, i18n } = useTranslation();
	const dispatch = useAppDispatch();
	const filterId = useAppSelector((state) => state.history.active);
	const ref = useRef<HTMLFormElement>(null);

	const { filterValues: filterValues, filterFields } = useAppSelector((state) => state.filters);

	const [present] = useIonToast();
	if (isOpen && !filterId) {
		present({
			position: "top",
			color: "secondary",
			header: "Filters not available",
			message: "Filter id not provided",
			duration: 5000,
		});
		onClose();
		return <React.Fragment />;
	}

	const handleReset = () => {
		if (filterId) dispatch(actions.RESET_FILTERS());
		onClose();
	};
	const handleSubmit = (filters: FormValues<IDataValue>) => {
		if (filterId)
			dispatch(
				actions.SET_FILTERS({
					repeatableId: filterId,
					filterValues: filters,
				}),
			);
		onClose();
	};

	return (
		<IonModal isOpen={isOpen} canDismiss={true} onWillDismiss={onClose}>
			<IonToolbar style={{ alignItems: "center", "--border-width": 0 }}>
				<IonTitle>{i18n.format(t("filters"), "capitalize")}</IonTitle>
				<IonButtons slot="end">
					<IonButton onClick={onClose}>
						<IonIcon src={close} size="small" style={{ strokeWidth: 1 }} />
					</IonButton>
				</IonButtons>
			</IonToolbar>
			<IonContent forceOverscroll={false}>
				<Filters
					formEngine={formEngine}
					record={record}
					form={form}
					siblingRecords={siblingRecords}
					ref={ref}
					filterFields={filterFields || []}
					initialFilters={filterValues || {}}
					setFilters={(filters) => handleSubmit(filters)}
					resetFilters={handleReset}
				/>
			</IonContent>
		</IonModal>
	);
};

export default FiltersModal;
