import React, { useContext } from "react";
import { get } from "react-hook-form";

import { IImage } from "interfaces/IImage";
import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";
import { ReportCoordinates } from "views/ReportView/components/widgets/ReportCoordinates";
import { ReportImage } from "views/ReportView/components/widgets/ReportImage";
import { ReportGroup } from "views/ReportView/components/widgets/ReportGroup";
import { ReportText } from "views/ReportView/components/widgets/ReportText";
import { ReportRepeatable } from "views/ReportView/components/widgets/ReportRepeatable";
import { ReportContext } from "./ReportRenderer";
import ReportDrawings from "./widgets/ReportDrawings";
import { IDrawingRef } from "interfaces/IDrawingRef";

interface ReportWidgetProps {
	formField: FormField;
	dataToRender: FormValues;
	ancestorHistory: string[];
}
export const WidgetFactory: React.FC<ReportWidgetProps> = (props: ReportWidgetProps) => {
	const { dataToRender: data, formField, ancestorHistory } = props;
	const { engine, changes } = useContext(ReportContext);

	const digField = engine?.getDigestedField(formField.name, ancestorHistory);

	const isGroup = formField.type === "inlineGroup" || formField.type === "drillDownGroup";
	const fieldValue = get(data, formField.name, formField.defaultValue);

	const shouldOmitUndefined = (fieldValue === undefined || fieldValue === "") && !isGroup;
	if (shouldOmitUndefined) {
		return <></>;
	}

	const path = [ancestorHistory, formField.name].join(".");
	const hasChanged = changes?.includes(path);

	if (digField?.relevant === false) return <React.Fragment />;

	if (formField.type === "repeatableGroup") {
		return <ReportRepeatable formField={formField} value={fieldValue} ancestorHistory={ancestorHistory} />;
	} else if (isGroup) {
		return <ReportGroup formField={formField} value={fieldValue} ancestorHistory={ancestorHistory} />;
	} else if (formField.type === "files") {
		return <React.Fragment />;
	} else if (formField.type === "images") {
		return <ReportImage formField={formField as FormField<IImage[]>} value={fieldValue} highlight={hasChanged} />;
	} else if (formField.type === "location") {
		return <ReportCoordinates formField={formField} value={fieldValue} highlight={hasChanged} />;
	} else if (formField.type === "drawings") {
		return <ReportDrawings formField={formField as FormField<IDrawingRef>} value={fieldValue} highlight={hasChanged} />;
	} else {
		return <ReportText formField={formField} value={fieldValue} highlight={hasChanged} />;
	}
};
