import React from "react";
import { IonItem, IonLabel } from "@ionic/react";

import { FormField } from "models/Form";
import { IDataValue } from "models/FormRecord";
import { useTranslation } from "react-i18next";

interface ReportCoordinatesProps {
	formField: FormField;
	value: IDataValue;
	highlight?: boolean;
}
export const ReportCoordinates: React.FC<ReportCoordinatesProps> = (props: ReportCoordinatesProps) => {
	const { value, formField, highlight = false } = props;
	const { t, i18n } = useTranslation();
	if (!value) return <React.Fragment />;

	const valueJSON = JSON.parse(value.toString());
	const coordinates: [number, number] | undefined =
		typeof valueJSON === "object" && valueJSON && valueJSON.coordinates && Array.isArray(valueJSON.coordinates)
			? (valueJSON.coordinates as [number, number])
			: undefined;
	if (!coordinates) return <React.Fragment />;

	return (
		<div className="no-break" id={`field-${formField.name}`}>
			<IonItem lines="none">
				<IonLabel className="ion-text-wrap" position="stacked" mode="ios">
					<div style={{ color: "var(--ion-color-medium)" }}>{formField.label}</div>
					<div className={highlight ? "highlighted" : undefined}>{`${i18n.format(
						t("common:latitude"),
						"capitalize",
					)}: ${coordinates[1].toFixed(6)}`}</div>
					<div className={highlight ? "highlighted" : undefined}>{`${i18n.format(
						t("common:longitude"),
						"capitalize",
					)}: ${coordinates[0].toFixed(6)}`}</div>
				</IonLabel>
			</IonItem>
		</div>
	);
};
