import React, { memo, PropsWithChildren } from "react";

import { IonIcon, IonInput, IonItem, IonLabel, IonNote, IonText } from "@ionic/react";
import { Controller } from "react-hook-form";

import { Divider } from "components/common/Form/components/Divider";
import { alertCircleOutline, informationCircleOutline } from "ionicons/icons";
import { FormField } from "../../../../models/Form";
import { useSmartFieldCtx } from "../hooks/useSmartForm";
import { useAppSelector } from "store";
import { useFilterFieldCtx } from "../hooks/useFilterForm";

interface IProps {
	field: FormField<string>;
	isFilterWidget?: boolean;
}

type InputType =
	| "date"
	| "datetime-local"
	| "email"
	| "month"
	| "number"
	| "password"
	| "search"
	| "tel"
	| "text"
	| "time"
	| "url"
	| "week";

type PropsType = IProps;
const Input: React.FC<PropsType> = (props) => {
	const { field, isFilterWidget } = props;

	const isFrozen = useAppSelector((state) => state.form.isFrozen);
	const { dig, control } = isFilterWidget ? useFilterFieldCtx(field) : useSmartFieldCtx(field);
	const { name, alert, relevant, required, hardRequired, disabled } = dig;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const numberInputOnWheelPreventChange = (event: any) => {
		// Prevent the input value change
		event.target.blur();

		// Prevent the page/container scrolling
		event.stopPropagation();

		// Refocus immediately, on the next tick (after the current function is done)
		setTimeout(() => {
			event.target.focus();
		}, 0);
	};

	const numberOnKeyDown = (event: React.KeyboardEvent<HTMLIonInputElement>, field: FormField<string>) => {
		if (field.type === "number") {
			const forbiddenKeys = ["e", "E", "/", "+"];
			if (field.isInteger) forbiddenKeys.push(".");
			if (field.min !== undefined && field.min !== null && field.min >= 0) forbiddenKeys.push("-");
			if (forbiddenKeys.includes(event.key)) {
				return event.preventDefault();
			}
		}
	};

	return (
		<div
			className="Input"
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{relevant && (
				<Controller
					name={name}
					control={control}
					rules={{
						// validate: isValid,
						required: hardRequired && relevant,
					}}
					defaultValue={field.defaultValue}
					render={({ field: fieldRenderProps }) => {
						return (
							<IonItem lines="none" disabled={isFrozen || disabled}>
								<IonLabel id={`${field.name}-label`} className="ion-text-wrap" position="stacked" mode="ios">
									{field.label}
									{(required || hardRequired) && <span style={{ color: "red" }}>&nbsp;*</span>}
								</IonLabel>
								<IonInput
									aria-labelledby={`${field.name}-label`}
									data-testid={`${field.type}:input`}
									// React Hook Form Managed
									ref={fieldRenderProps.ref}
									name={fieldRenderProps.name}
									value={fieldRenderProps.value as string}
									onIonBlur={fieldRenderProps.onBlur}
									onIonChange={fieldRenderProps.onChange}
									// Other config
									type={
										[
											"number",
											"date",
											"datetime-local",
											"email",
											"month",
											"password",
											"search",
											"tel",
											"text",
											"time",
											"url",
											"week",
										].includes(field.type)
											? (field.type as InputType)
											: "text"
									}
									placeholder={field.description}
									// eslint-disable-next-line @typescript-eslint/no-empty-function
									onKeyDown={(evt) => numberOnKeyDown(evt, field)}
									// required={required}
									enterkeyhint="next"
									min={field.min}
									max={field.max}
									step={field.step}
									onWheel={numberInputOnWheelPreventChange}
								/>
								<Divider color="var(--ion-color-medium)" />
								{alert && (
									<IonNote
										data-testid={`${field.type}:note`}
										color={field.alertColor}
										style={{
											display: "flex",
											gap: ".25rem",
											alignItems: "start",
											textAlign: "start",
											margin: ".25rem 0",
											fontSize: ".7rem",
										}}
									>
										<IonIcon
											icon={
												field.alertIcon === "informationCircleOutline" ? informationCircleOutline : alertCircleOutline
											}
											color={field.alertColor}
											size="small"
										/>
										<IonText>{alert}</IonText>
									</IonNote>
								)}
							</IonItem>
						);
					}}
				/>
			)}
		</div>
	);
};
const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(Input, propsAreEqual);
export default Memoized;
