import React from "react";

import { IonItem, IonList, IonLabel } from "@ionic/react";

import { IImage } from "../../../../interfaces/IImage";
import { FormField } from "../../../../models/Form";
import Gallery from "../components/Gallery";
import SketchCanvas from "../components/SketchCanvas";
import { useAppSelector } from "store";
import { useSmartFieldCtx } from "../hooks/useSmartForm";

interface IProps {
	field: FormField<IImage[]>;
}

const Sketches: React.FC<IProps> = (props) => {
	const { field } = props;

	const isFrozen = useAppSelector((state) => state.form.isFrozen);
	const { dig } = useSmartFieldCtx(field);

	const { relevant, required, hardRequired, disabled } = dig;

	return (
		<div
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{relevant ? (
				<IonItem lines="none">
					<IonList style={{ width: "100%" }}>
						<IonLabel className="ion-text-wrap capitalize-first-letter" position="stacked" mode="ios">
							{field.label}
							{(required || hardRequired) && <span style={{ color: "red" }}>&nbsp;*</span>}
						</IonLabel>
						<Gallery field={field} variant="image">
							{!isFrozen && disabled && <SketchCanvas field={field} />}
						</Gallery>
					</IonList>
				</IonItem>
			) : null}
		</div>
	);
};
export default Sketches;
