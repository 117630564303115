import React, { useMemo } from "react";

import { IonAccordion, IonAccordionGroup, IonIcon, IonItem, IonLabel } from "@ionic/react";
import * as iconLibrary from "ionicons/icons";

import { groupStyles } from "components/common/Form/groupStyles";
import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";

import { getChildrenTypes } from "../utils/getChildrenTypes";
import { FormFieldFactory } from "../utils/FormFieldFactory";
import { checkmarkCircleOutline, ellipseOutline } from "ionicons/icons";
import { useSmartFieldCtx } from "../hooks/useSmartForm";

interface IProps {
	field: FormField<FormValues>;
	level: number;
	variant: "dark" | "gray" | "white";
	parentInlineIds?: string[];
}
type PropsType = IProps;

const InlineGroup: React.FC<PropsType> = (props) => {
	const { field, level, variant, parentInlineIds = [] } = props;

	const siblingTypes = useMemo(() => field.children?.map((it) => it.type), [field]);

	const { dig, isCompleted } = useSmartFieldCtx(field);

	const { relevant, disabled, iconConfig } = dig;

	return relevant ? (
		<IonAccordionGroup id={`field-${field.name}`}>
			<IonAccordion disabled={disabled}>
				{/* Different style depending on level = 1 or level = 2 */}
				<IonItem
					data-testid={`${field.type}.button`}
					slot="header"
					className="Group"
					button={true}
					detail={false}
					lines="none"
					style={{
						...groupStyles(variant).itemStyle,
						...{
							alignItems: "center",
							minHeight: "3rem",
							borderBottom: "1px solid #CBD5E0",
							width: "100%",
							cursor: "pointer",
						},
					}}
				>
					<IonLabel
						className="ion-text-wrap"
						style={{
							fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', Roboto, sans-serif",
							textOverflow: "ellipsis",
							overflow: "hidden",
							fontSize: "0.875rem",
							lineHeight: "1rem",
						}}
					>
						{field.label}
					</IonLabel>
					{!!field.iconConfig && (
						<IonIcon
							slot="end"
							style={{
								color: iconConfig?.color,
							}}
							icon={iconLibrary[iconConfig?.icon as unknown as keyof typeof iconLibrary]}
							title={field.iconConfig.label}
						/>
					)}
					<IonIcon
						src={isCompleted ? checkmarkCircleOutline : ellipseOutline}
						slot="end"
						style={{
							color: isCompleted ? "var(--ion-color-success)" : groupStyles(variant).statusIconStyle.color,
						}}
					/>
				</IonItem>
				{/* Render children */}
				<div slot="content">
					{(field.children || []).map((child) => (
						<FormFieldFactory
							key={child.name}
							field={child}
							parentType={field.type}
							level={level}
							siblingTypes={siblingTypes}
							childrenTypes={getChildrenTypes(child)}
							parentInlineIds={[...parentInlineIds, field.name]}
						/>
					))}
				</div>
			</IonAccordion>
		</IonAccordionGroup>
	) : (
		<React.Fragment />
	);
};

export default InlineGroup;
