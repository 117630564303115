import React from "react";

import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import * as iconLibrary from "ionicons/icons";

import { groupStyles } from "components/common/Form/groupStyles";
import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";
import { useAppDispatch } from "store";
import { actions as historyActions } from "store/slices/history";
import { checkmarkCircleOutline, ellipseOutline } from "ionicons/icons";
import { chevronRight } from "assets/icons";
import { useSmartFieldCtx } from "../hooks/useSmartForm";

interface IProps {
	field: FormField<FormValues>;
	variant: "dark" | "gray" | "white";
	parentInlineIds?: string[];
}
type PropsType = IProps;

export const DrillDownGroup: React.FC<PropsType> = (props) => {
	const { field, variant, parentInlineIds = [] } = props;
	const dispatch = useAppDispatch();

	const { dig, isCompleted } = useSmartFieldCtx(field);

	const { relevant, disabled, iconConfig } = dig;

	return relevant ? (
		<IonItem
			data-testid={`${field.type}.button`}
			className="Group"
			id={`field-${field.name}`}
			onClick={() => {
				if (parentInlineIds.length) {
					dispatch(historyActions.pushHistoryMany([...parentInlineIds, field.name]));
				} else dispatch(historyActions.pushHistory(field.name));
			}}
			button={true}
			detail={false}
			disabled={disabled}
			lines="none"
			style={{
				...groupStyles(variant).itemStyle,
				...{
					alignItems: "center",
					minHeight: "3rem",
					borderBottom: "1px solid #CBD5E0",
					width: "100%",
					cursor: "pointer",
				},
			}}
		>
			<IonLabel
				className="ion-text-wrap"
				style={{
					fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', Roboto, sans-serif",
					fontSize: "0.875rem",
					lineHeight: "1rem",
				}}
			>
				{field.label}
			</IonLabel>

			{!!field.iconConfig && (
				<IonIcon
					slot="end"
					style={{
						color: iconConfig?.color,
					}}
					icon={iconLibrary[iconConfig?.icon as unknown as keyof typeof iconLibrary]}
					title={field.iconConfig.label}
				/>
			)}
			<IonIcon
				src={isCompleted ? checkmarkCircleOutline : ellipseOutline}
				slot="end"
				style={{
					color: isCompleted ? "var(--ion-color-success)" : groupStyles(variant).statusIconStyle.color,
				}}
			/>
			<IonIcon
				src={chevronRight}
				slot="end"
				size="small"
				style={{
					color: `${groupStyles(variant).chevronIconStyle.color}`,
					marginRight: "0",
					marginLeft: "10px",
				}}
			/>
		</IonItem>
	) : (
		<React.Fragment />
	);
};
