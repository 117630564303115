import React, { useEffect, useRef, useState } from "react";

import { IonItem, IonLabel, IonCard, IonNote } from "@ionic/react";

import { FormField } from "../../../../models/Form";
import { IDrawingRef } from "interfaces/IDrawingRef";
import { IDataValue } from "models/FormRecord";
import { parseDrawing } from "components/common/Form/components/Drawings/utils/parseDrawing";

interface IProps {
	formField: FormField<IDrawingRef>;
	value: IDataValue;
	highlight?: boolean;
}

type PropsType = IProps;
const ReportDrawings: React.FC<PropsType> = (props) => {
	const { formField, value } = props;

	const parsedValue = parseDrawing(value);

	const drawingReference = parsedValue?.ref ?? null;
	const imageRef = (formField.choices || []).find((choice) => choice.value === drawingReference);
	const filepath = ("drawings/" + imageRef?.filepath) as string;
	const imageLabel = imageRef?.label;
	const imgRef = useRef<HTMLImageElement>(null);

	const point: [number, number] | undefined = (parsedValue as IDrawingRef)?.geojson?.geometry?.coordinates ?? undefined;
	const srcImage = window.location.hostname === "localhost" ? `${origin}/placeholder.png` : `${origin}/${filepath}`;
	const [aspectRatio, setAspectRatio] = useState<number | null>(null);

	const handleImageLoad = () => {
		const imgElement = imgRef.current;
		if (imgElement && imgElement.naturalWidth && imgElement.naturalHeight) {
			setAspectRatio(imgElement.naturalHeight / imgElement.naturalWidth);
		}
	};

	useEffect(() => {
		const imgElement = imgRef.current;
		if (imgElement) {
			imgElement.addEventListener("load", handleImageLoad);
			return () => imgElement.removeEventListener("load", handleImageLoad);
		}
	}, []);

	return (
		<div
			id={`field-${formField.name}`}
			className="no-break"
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			<IonItem lines="none">
				<IonLabel className="ion-text-wrap capitalize-first-letter" position="stacked" mode="ios">
					{formField.label}
				</IonLabel>
				<IonNote>{imageLabel}</IonNote>
				{parsedValue?.ref !== undefined && parsedValue?.ref !== null && (
					<div>
						<IonCard
							mode="md"
							style={{
								objectFit: "contain",
								margin: "0 0 1rem 0",
								height: "15rem",
								boxShadow: "none",
								border: "1px solid var(--ion-color-gray200)",
								borderRadius: "4px",
							}}
						>
							<div
								id="image-container"
								style={{
									height: "100%",
									width: "100%",
								}}
							>
								<img
									id="image-viewer"
									loading="lazy"
									style={{
										height: "100%",
										width: "100%",
									}}
									ref={imgRef}
									src={srcImage}
									alt={""}
								></img>
								{point && aspectRatio && (
									<div
										style={{
											width: "12px",
											height: "12px",
											opacity: "1",
											display: "block",
											left: `calc(${point[0] * 100}%)`,
											top: `calc(${aspectRatio ? (point[1] * 100) / aspectRatio : 0}%)`,
											transform: "translate(-50%,-100%)",
											position: "absolute",
										}}
									>
										<svg
											width="100%"
											height="100%"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
											style={{ display: "block", justifyContent: "center", alignItems: "center" }}
										>
											<path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" fill="#006935" />
											<circle cx="12" cy="10" r="3" fill="#ffff" />
										</svg>
									</div>
								)}
							</div>
						</IonCard>
					</div>
				)}
			</IonItem>
		</div>
	);
};
export default ReportDrawings;
