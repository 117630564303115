import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { DottedFieldPath, FormEngine, HashedDigestedFieldReferenceLibrary, dotPath } from "@arup-group/dhub-forms-engine";
import Form, { FormField } from "models/Form";
import FormRecord, { IDataValue } from "models/FormRecord";
import { useAppSelector } from "store";
import get from "lodash.get";

export const FormContext = createContext<ReturnType<typeof useSmartForm> | undefined>(undefined);

export const useSmartForm = (
	form: Form,
	record: FormRecord,
	siblingRecords: FormRecord[] = [],
	dfl?: HashedDigestedFieldReferenceLibrary,
) => {
	const { projectRef, assetId } = useParams<{ projectRef: string; assetId: string }>();
	const [changes, setChanges] = useState<Set<string>>(new Set());
	const engine = useMemo(
		() => new FormEngine(projectRef, assetId, form, [record, ...siblingRecords], true, false, dfl),
		[projectRef, assetId, form.id, record.id],
	);

	const methods = useForm({
		reValidateMode: "onBlur",
	});

	const applyChange = useCallback(
		(
			fieldName: DottedFieldPath,
			value: IDataValue,
			options?: Partial<{
				shouldValidate: boolean;
				shouldTouch: boolean;
				shouldDirty: boolean;
				shouldSetSelf: boolean;
			}>,
		) => {
			if (options?.shouldSetSelf ?? true) methods.setValue(fieldName, value, options);
			const { data: newData, valueChanges: newChanges } = engine.digestSingleChange(fieldName, value);
			// Slicing the first change out since that's already processed by react-hook-forms
			for (const change of newChanges.slice(1)) {
				const newValue = get(newData, change);
				console.log("Setting", change, "=", newValue);
				methods.setValue(change, newValue, {
					shouldDirty: options?.shouldDirty ?? true,
					shouldTouch: options?.shouldTouch ?? true,
					shouldValidate: options?.shouldValidate ?? true,
				});
			}
			setChanges((curr) => new Set([...curr, ...newChanges]));
		},
		[],
	);

	// Initializes form on first render
	useEffect(() => {
		console.log("Resetting form!!");
		methods.reset(engine.getData());
	}, []);

	useEffect(() => {
		const subscription = methods.watch((data, { name, type }) => {
			if (!name) return;
			// console.log("Detected change in field:", name, data, type);

			if (type === undefined) {
				// This is intended to short circuit changes triggered by this very mechanism and not controlled fields,
				// avoiding an otherwise infinte loop
				return;
			}

			const value = get(data, name) as IDataValue;
			applyChange(name, value, { shouldSetSelf: false });
		});
		return () => subscription.unsubscribe();
	}, []);

	return { engine, ...methods, changes: [...changes], applyChange };
};

export const useSmartFieldCtx = (field: FormField, itemIdx?: number) => {
	const history = useAppSelector((state) => state.history.list);
	const ctx = useContext(FormContext);
	if (!ctx) throw new Error("Form context not available");

	const initDig = ctx.engine.getDigestedField(field.name, history, itemIdx);
	const [state, setState] = useState({
		dig: initDig,
		isCompleted: initDig ? ctx.engine.getIsCompleted(dotPath(initDig.bakedPath)) : false,
	});

	const { dig, isCompleted } = state;
	if (!dig) {
		throw new Error("Digested field not found");
	}

	useEffect(() => {
		const dottedPath = dotPath(dig.bakedPath);
		ctx.engine.subscribe(dottedPath, () => {
			setState({
				dig: ctx.engine.getDigestedField(field.name, history, itemIdx),
				isCompleted: ctx.engine.getIsCompleted(dottedPath),
			});
		});
		return () => ctx.engine.unsubscribe(dottedPath);
	}, [ctx.engine]);

	return {
		dig,
		isCompleted,
		currentValue: ctx.engine.getFieldData(field.name, history, itemIdx),
		items: field.type === "repeatableGroup" ? ctx.engine.getFieldItems(field.name, history) : [],
		control: ctx.control,
		applyChange: ctx.applyChange,
	};
};
