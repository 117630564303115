import React, { PropsWithChildren, useState } from "react";

import { IonActionSheet } from "@ionic/react";
import { trash, close } from "ionicons/icons";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { IFile } from "interfaces/IFile";
import { IImage } from "interfaces/IImage";
import { FormField } from "models/Form";
import LocalImage from "models/LocalImage";
import LocalFile from "models/LocalFile";

import Modal from "./ImageModal";
import ImageFallback from "./ImageFallback";
import FileThumbnail from "./FileThumbnail";

import "./Gallery.styles.css";
import { useSmartFieldCtx } from "../../hooks/useSmartForm";

interface IProps extends PropsWithChildren {
	variant: "image" | "file";
	field: FormField<IImage[]> | FormField<IFile[]>;
}

type PropsType = IProps;
const Gallery: React.FC<PropsType> = (props) => {
	const { field, variant } = props;

	const { t, i18n } = useTranslation();
	const [selected, setSelected] = useState<IImage | IFile>();
	const [toBeDeletedId, setToBeDeletedId] = useState<string>();

	const { dig, currentValue, control, applyChange } = useSmartFieldCtx(field);

	const { name } = dig;

	const allAvailable = useWatch({ name, control }) as IImage[] | IFile[];
	const availableImages = (allAvailable || []).filter((it) => !it._is_deleted);
	const deleteItem = async () => {
		if (!toBeDeletedId) return;
		const itemIdx = availableImages.findIndex((it) => it.filepath === toBeDeletedId);
		const toDelete = availableImages[itemIdx];
		if (!toDelete) throw new Error(`Attempting to delete unnavailable ${variant} ${toBeDeletedId}`);
		await (variant === "image" ? LocalImage : LocalFile)
			.delete(toDelete)
			.catch(() => console.log(`Local ${variant} to delete ${toDelete.filepath} not found`))
			.finally(() => {
				applyChange &&
					applyChange(
						name,
						(currentValue as IImage[]).map((ph) =>
							ph.filepath === toDelete.filepath ? { ...ph, _is_deleted: true } : ph,
						),
						{ shouldDirty: true },
					);
				console.log("SIIIII");
			});
		const remaining = availableImages.filter((it) => it.filepath !== toBeDeletedId);
		const lastIdx = availableImages.length - 1;
		const nextSelection = itemIdx === lastIdx ? remaining[itemIdx - 1] : remaining[itemIdx];
		setSelected(nextSelection);
	};

	return (
		<>
			<div className="gallery">
				{props.children}
				{availableImages
					.filter((it) => !it._is_deleted)
					.map((it) =>
						variant === "image" ? (
							<ImageFallback
								key={it.filepath}
								style={{
									cursor: "pointer",
									objectFit: "contain",
								}}
								photo={it}
								onClick={() => setSelected(it)}
							/>
						) : (
							<FileThumbnail
								key={it.filepath}
								file={it as IFile}
								onDelete={(toDelete: string) => {
									setToBeDeletedId(toDelete);
								}}
							/>
						),
					)}
			</div>
			{variant === "image" && (
				<Modal
					items={availableImages}
					selected={selected}
					setSelected={setSelected}
					onDelete={(selected) => setToBeDeletedId(selected)}
				/>
			)}

			<IonActionSheet
				isOpen={toBeDeletedId !== undefined}
				header={i18n.format(t("delete_file_msg"), "capitalize")}
				buttons={[
					{
						text: i18n.format(t("delete"), "capitalize"),
						role: "destructive",
						icon: trash,
						handler: deleteItem,
					},
					{
						text: i18n.format(t("cancel"), "capitalize"),
						icon: close,
						role: "cancel",
					},
				]}
				onDidDismiss={() => setToBeDeletedId(undefined)}
			/>
		</>
	);
};
export default Gallery;
