import React from "react";

import { useAppSelector } from "store";

const NavigationBreadcrumbs: React.FC = () => {
	const formName = useAppSelector((state) => state.form.active?.name);
	const fieldReferenceLibrary = useAppSelector((state) => state.form.fieldReferenceLibrary);
	const { list } = useAppSelector((state) => state.history);

	const breadCrumbs = [
		formName,
		...list
			.map((name) => {
				return fieldReferenceLibrary[name]?.shortLabel || fieldReferenceLibrary[name]?.label || name;
			})
			.slice(0, -1),
	].filter((label): label is string => !!label);

	const summarizedBreadCrums =
		breadCrumbs.length > 3 ? [...breadCrumbs.slice(0, 2), "...", breadCrumbs.slice(-2, -1)] : breadCrumbs;

	if (list.length === 0) return <></>;

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "2rem",
				color: "#718096",
				width: "100%",
				padding: "0.5rem 1.5rem",
				backgroundColor: "var(--ion-color-gray100)",
			}}
		>
			<div
				style={{
					fontWeight: "400",
					fontSize: "0.7rem",
					overflow: "hidden",
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					direction: "rtl",
					textAlign: "end",
					width: "100%",
				}}
			>
				<span style={{ direction: "ltr", unicodeBidi: "bidi-override" }}>{summarizedBreadCrums.join(" / ")}</span>
			</div>
		</div>
	);
};

export default NavigationBreadcrumbs;
