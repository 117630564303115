import Asset from "models/Asset";
import DigestedFieldLibrary from "models/DigestedFieldLibrary";
import FormRecord from "models/FormRecord";

export const requestStoragePersistance = async () => {
	// Try to make sure we can persist data
	if (navigator.storage && navigator.storage.persist) {
		navigator.storage.persist().then((persistent) => {
			if (persistent) {
				console.info("Successfully set persistent storage");
			} else {
				console.info("Could not ensure persisting storage");
			}
		});
	} else {
		console.log("navigator.storage.persist API unavailable");
	}
};

export interface QuotaInfo {
	usage: number | undefined;
	quota: number | undefined;
}

export const estimateStorageQuota = async () => {
	const quotaInfo: QuotaInfo = {
		usage: undefined,
		quota: undefined,
	};
	if (navigator.storage && navigator.storage.estimate) {
		// Find out quota
		const estimate = await navigator.storage.estimate();
		if (estimate && estimate.usage !== undefined && estimate.quota !== undefined) {
			quotaInfo.usage = Math.round(estimate.usage / 1024 / 1024);
			quotaInfo.quota = Math.round(estimate.quota / 1024 / 1024);
			const percentageUsed = (estimate.usage / estimate.quota) * 100;
			console.info(
				`You've used ${percentageUsed.toFixed(1)}% of the available storage (${quotaInfo.usage}Mb / ${
					quotaInfo.quota
				}Mb).`,
			);
			const remaining = quotaInfo.quota - quotaInfo.usage;
			console.info(`You can write up to ${remaining} more MB.`);
		}
	} else {
		console.log("navigator.storage.estimate API unavailable");
	}
	return quotaInfo;
};

export const getAssetsWithLastDate = async () => {
	const allRecords = await FormRecord.getAll({ includeDeleted: true, includeInactive: true });

	return allRecords.reduce((acc, curr) => {
		const prev = acc[curr.asset_id];
		const prevSyncDate: Date | undefined = prev?.lastSynced;
		const currSyncDate = curr.date_synced;
		const prevUpdateDate: Date | undefined = prev?.lastUpdated;
		const currUpdateDate = curr.date_updated;

		if (!currSyncDate || !currUpdateDate) return acc;
		const newSyncDate = !prevSyncDate ? currSyncDate : currSyncDate > prevSyncDate ? currSyncDate : prevSyncDate;
		const newUpdateDate = !prevUpdateDate
			? currUpdateDate
			: currUpdateDate > prevUpdateDate
			? currUpdateDate
			: prevUpdateDate;

		return {
			...acc,
			[curr.asset_id]: { lastSynced: newSyncDate, lastUpdated: newUpdateDate },
		};
	}, {} as { [assetId: string]: { lastSynced: Date; lastUpdated: Date } });
};

export const getAssetAnalytics = async () => {
	const allAssets = await Asset.getAll(true);
	const assetLibrary = Object.fromEntries(allAssets.map((it) => [it.id, it]));

	const NowInMs = new Date().getTime();
	const oneDayInMs = 24 * 3600 * 1000;

	const assetWithLastDates = await getAssetsWithLastDate();

	const syncStatusAnalytics = {
		synced: Object.values(assetWithLastDates).filter((it) => it.lastSynced && it.lastSynced >= it.lastUpdated).length,
		toUpload: Object.values(assetWithLastDates).filter((it) => !it.lastSynced || it.lastSynced < it.lastUpdated).length,
	};

	const deliveryStatusAnalytics = Object.keys(assetWithLastDates)
		.map((assetId) => assetLibrary[assetId]?.status?.status)
		.filter((it): it is string => !!it)
		.reduce((acc, curr) => {
			acc[curr] = (acc[curr] ?? 0) + 1;
			return acc;
		}, {} as { [deliveryStatus: string]: number });

	const lastSyncedAnalytics = {
		"7d": Object.values(assetWithLastDates).filter((it) => it.lastSynced?.getTime() < NowInMs - oneDayInMs * 7).length,
		"14d": Object.values(assetWithLastDates).filter((it) => it.lastSynced?.getTime() < NowInMs - oneDayInMs * 14)
			.length,
		"30d": Object.values(assetWithLastDates).filter((it) => it.lastSynced?.getTime() < NowInMs - oneDayInMs * 30)
			.length,
		"60d": Object.values(assetWithLastDates).filter((it) => it.lastSynced?.getTime() < NowInMs - oneDayInMs * 60)
			.length,
		"90d": Object.values(assetWithLastDates).filter((it) => it.lastSynced?.getTime() < NowInMs - oneDayInMs * 90)
			.length,
		"120d": Object.values(assetWithLastDates).filter((it) => it.lastSynced?.getTime() < NowInMs - oneDayInMs * 120)
			.length,
		"180d": Object.values(assetWithLastDates).filter((it) => it.lastSynced?.getTime() < NowInMs - oneDayInMs * 180)
			.length,
		"360d": Object.values(assetWithLastDates).filter((it) => it.lastSynced?.getTime() < NowInMs - oneDayInMs * 360)
			.length,
	};

	const analytics = {
		total: Object.keys(assetWithLastDates).length,
		bySyncStatus: syncStatusAnalytics,
		byDeliveryStatus: deliveryStatusAnalytics,
		byLastSynced: lastSyncedAnalytics,
	};
	return analytics;
};

export const deleteOldRecords = async (olderThanDays = 90) => {
	const allRecords = await FormRecord.getAll({ includeDeleted: true, includeInactive: true });
	const assetWithLastDates = Object.entries(await getAssetsWithLastDate()).map(([k, v]) => ({ assetId: k, ...v }));
	const acceptedOffsetInMs = olderThanDays * 24 * 3_600e3; // Three months
	const limitDate = new Date(new Date().getTime() - acceptedOffsetInMs);
	const assetIdsToDelete = assetWithLastDates
		.filter((it) => it.lastSynced >= it.lastUpdated && it.lastSynced < limitDate)
		.map((it) => it.assetId);
	const recordsToDelete = allRecords.filter((r) => assetIdsToDelete.includes(r.asset_id));
	const summary = `Deleting ${recordsToDelete.length} records (${assetIdsToDelete.length} assets) from local storage, since they had already been synced more than ${olderThanDays} days ago`;
	console.info(
		summary,
		recordsToDelete.map((it) => it.id),
	);
	await DigestedFieldLibrary.deleteMany(recordsToDelete.map((r) => r.id));
	recordsToDelete.forEach(async (r) => await r.hardDelete());
	return summary;
};
