export const useDeviceId = () => {
	let deviceId = localStorage.getItem("deviceId");

	if (!deviceId) {
		if (window?.crypto?.randomUUID) {
			deviceId = window.crypto.randomUUID();
			localStorage.setItem("deviceId", deviceId);
		}
	}

	return deviceId;
};
